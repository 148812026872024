<template>
  <!-- ダイアログ - 共通 -->
  <DialogComponent
    :dialog="state.dialog"
    :dialogInfo="state.dialogInfo"
    @dialog-event="dialogEvent"
  />
  <v-container>
    <HeaderComponent />
    <v-col cols="12" class="mx-0 px-0">
      <TitleComponent title="アルバム設定" />
      <v-select
        :items="state.nur_mst_grade"
        item-title="grade_name"
        item-value="id"
        v-model="state.class"
        label="クラス"
        variant="underlined"
        color="keyakiwakaba"
        @update:modelValue="changeClass"
        :rules="classRules"
      ></v-select>
      <v-row justify="end" no-gutters>
        <v-col cols="auto">
          <v-switch
            v-model="state.nur_memory.open_flg"
            color="keyakiwakaba"
            :model-value="state.nur_memory.open_flg"
            :label="isOpen()"
            @click="openDialog('change')"
            hide-details
            :disabled="state.class === ''"
          ></v-switch>
        </v-col>
      </v-row>

      <!-- 画像登録 -->
      <v-card variant="outlined" flat class="regImgCard">
        <v-toolbar
          density="compact"
          color="member"
          dark="false"
          class="regImgTitle"
        >
          <v-toolbar-title>画像登録</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon>
            <v-icon @click="state.isOpenRegist = !state.isOpenRegist">{{
              state.isOpenRegist ? "mdi-minus-box" : "mdi-plus-box"
            }}</v-icon>
          </v-btn>
        </v-toolbar>
        <v-col cols="auto" align="center" v-if="state.isOpenRegist">
          <v-file-input
            accept="image/png,image/jpeg"
            multiple
            variant="underlined"
            label="画像を選択"
            v-model:model-value="state.uploadFiles"
          ></v-file-input>
          <v-col class="text-center">
            <v-btn
              color="keyakiwakaba"
              :disabled="!hasRegistImgRequired"
              @click="openDialog('regImg')"
            >
              <p>登録</p>
            </v-btn>
          </v-col>
        </v-col>
      </v-card>

      <!-- 画像順変更 -->
      <v-card variant="outlined" flat class="mt-5 regSortCard">
        <v-toolbar density="compact" color="admin" dark="false">
          <v-toolbar-title>画像順変更</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon>
            <v-icon @click="state.isOpenSort = !state.isOpenSort">{{
              state.isOpenSort ? "mdi-minus-box" : "mdi-plus-box"
            }}</v-icon>
          </v-btn>
        </v-toolbar>
        <v-col
          class="text-center"
          v-if="state.isOpenSort && state.nur_memory.images.length === 0"
        >
          <p class="text-disabled">画像が登録されていません。</p>
        </v-col>
        <draggable
          v-model="state.nur_memory.images"
          group="transition-group"
          @start="drag = true"
          @end="drag = false"
          item-key="id"
          handle=".item-handle"
          @change="changeSort()"
          v-bind="dragOptions"
          v-if="state.isOpenSort"
        >
          <template #item="{ element: img, index: idx }">
            <v-row class="ml-0 my-2 mr-1 pa-0 elevation-3">
              <v-col cols="2" class="ma-0 pa-0" align-self="center">
                <v-btn
                  icon="mdi-close"
                  size="x-small"
                  variant="text"
                  color="error"
                  class="ma-0 removeRow"
                  @click="openDialog('delete', img.id)"
                ></v-btn>
                {{ idx + 1 }}
              </v-col>
              <v-col cols="10" class="ma-0 pa-0">
                <v-row
                  :class="
                    isUnRegisteredSort(img.id, idx)
                      ? 'regist-area-changed'
                      : 'regist-area'
                  "
                >
                  <v-col cols="2" class="item-handle text-center ma-0 pa-0">
                    <v-icon icon="mdi-drag-vertical" class="handle" />
                  </v-col>
                  <v-divider inset vertical></v-divider>
                  <v-col cols="10" class="ma-0 pa-0">
                    <v-img :src="img.url" max-height="140" contain />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </template>
        </draggable>
        <v-col
          cols="4"
          v-if="state.isOpenSort && state.nur_memory.images.length > 0"
          class="fixedBtn"
        >
          <v-btn
            block
            rounded="xl"
            color="keyakiwakaba"
            :disabled="!hasRegistSortRequired"
            @click="openDialog('sort')"
          >
            並び順登録
          </v-btn>
        </v-col>
      </v-card>
    </v-col>
  </v-container>
  <v-snackbar
    :timeout="2000"
    :color="state.snack.color"
    v-model="state.snack.visible"
    rounded="pill"
    min-width="95%"
  >
    <p class="text-center">{{ state.snack.msg }}</p>
  </v-snackbar>
</template>

<script>
import { logout } from "@/utility";
import { reactive } from "vue";
import HeaderComponent from "@/components/HeaderComponent.vue";
import TitleComponent from "@/components/TitleComponent.vue";
import DialogComponent from "@/components/DialogComponent.vue";
import { classRules } from "@/validationRules";
import {
  BASE_URL,
  DELETE_CONF_DIALOG_INFO,
  ALBUM_OPEN_CONF_DIALOG_INFO,
  ALBUM_CLOSE_CONF_DIALOG_INFO,
  ALBUM_REG_EDIT_CONF_DIALOG_INFO,
  ALBUM_SORT_EDIT_CONF_DIALOG_INFO,
} from "@/data";
import draggable from "vuedraggable";
import axios from "@/plugins/axios";

export default {
  name: "RegistAlbumView",
  components: { DialogComponent, HeaderComponent, TitleComponent, draggable },
  display: "Transitions",
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    hasRegistImgRequired() {
      return this.state.class && this.state.uploadFiles.length > 0;
    },
    hasRegistSortRequired() {
      return this.state.class && this.state.nur_memory.images.length > 0;
    },
    isUnRegisteredSort() {
      return (targetId, idx) =>
        this.state.isSorted && targetId !== this.state.beforeOrder[idx];
    },
  },
  setup() {
    const userInfo = JSON.parse(sessionStorage.getItem("USER_INFO"));
    if (!userInfo) {
      logout();
    }

    const state = reactive({
      uploadFiles: [],
      class: "",
      isOpenRegist: false,
      isOpenSort: false,
      nur_mst_grade: [],
      nur_memory: { id: null, open_flg: false, images: [] },
      sortedIds: [],
      isSorted: false,
      beforeOrder: [],
      beforeOpenState: false,
      dialog: false,
      dialogInfo: { text: "", buttons: [] },
      snack: { visible: false, msg: "" },
      removeTargetId: null,
    });

    /**
     * 初期表示
     * @returns {Promise<void>}
     */
    const getInitialData = async () => {
      try {
        const data = await axios(
          `${BASE_URL}regist_memories?grade_id=${state.class}`,
          "get"
        );
        setResData(data);
      } catch (error) {
        if (error.response?.data?.msg) {
          getSnack(error.response.data.msg, "error");
        } else {
          alert(error);
        }
      }
    };
    getInitialData();

    /**
     * 取得データのセット
     * @param data APIレスポンス
     */
    const setResData = (data) => {
      state.nur_mst_grade = data.nur_mst_grade;
      state.nur_memory.id = data.nur_memory.id;
      state.nur_memory.open_flg = data.nur_memory.open_flg;
      state.nur_memory.images = data.nur_memory.images;
      // 変更前の公開状態保持用フラグ
      state.beforeOpenState = data.nur_memory.open_flg;
      // 変更前の順序
      state.beforeOrder = data.nur_memory.images.map((img) => img.id);
    };

    /**
     * ダイアログ分岐
     * @param kinds
     * @param idx
     */
    const openDialog = (kinds, idx = null) => {
      switch (kinds) {
        case "delete":
          state.dialogInfo = DELETE_CONF_DIALOG_INFO;
          state.removeTargetId = idx;
          state.dialog = true;
          break;
        case "change":
          state.dialogInfo = state.nur_memory.open_flg
            ? ALBUM_CLOSE_CONF_DIALOG_INFO
            : ALBUM_OPEN_CONF_DIALOG_INFO;
          state.dialog = true;
          break;
        case "regImg":
          if (state.nur_memory.open_flg) {
            state.dialogInfo = ALBUM_REG_EDIT_CONF_DIALOG_INFO;
            state.dialog = true;
          } else {
            registImages();
          }
          break;
        case "sort": {
          if (state.nur_memory.open_flg) {
            state.dialogInfo = ALBUM_SORT_EDIT_CONF_DIALOG_INFO;
            state.dialog = true;
          } else {
            registSort();
          }
          break;
        }
        default:
      }
    };

    /**
     * ダイアログアクション
     * @param kinds
     */
    const dialogEvent = (kinds) => {
      switch (kinds) {
        // 削除確認ダイアログ
        case "quit": // 削除しないでもどる
          state.removeTargetId = null;
          break;
        case "delete":
          removeRow(state.removeTargetId);
          break;
        // 公開状態変更確認ダイアログ
        case "toOpen": // fallthrough
        case "toClose":
          changeOpenFlg();
          break;
        case "cancel":
          state.nur_memory.open_flg = state.beforeOpenState;
          break;
        // 公開中アルバムの変更確認
        case "update":
          registImages();
          break;
        case "undo":
          state.uploadFiles = [];
          break;
        case "updSort":
          registSort();
          break;
        default:
          state.removeTargetId = null;
          state.nur_memory.open_flg = state.beforeOpenState;
          break;
      }
      state.dialog = false;
    };

    /**
     * 受け取ったメッセージでスナックバーを表示させる
     * @param {String} msg メッセージ
     * @param color
     */
    const getSnack = (msg, color = "success") => {
      state.snack = {
        visible: true,
        msg,
        color,
      };
    };

    /**
     * クラスセレクトボックスの変更アクション
     */
    const changeClass = () => {
      // 並べ替えの状態をリセット
      state.sortedIds = [];
      state.isSorted = false;
      getInitialData();
    };

    /**
     * 表示順配列の作成
     */
    const changeSort = () => {
      state.sortedIds = state.nur_memory.images.map((img) => img.id);
      state.isSorted = true;
    };

    /**
     * アルバム画像の登録
     * @returns {Promise<void>}
     */
    const registImages = async () => {
      // state.isLoading = true;
      const params = {
        id: state.nur_memory.id,
        nur_mst_grade_id: state.class,
        pages: state.uploadFiles,
      };
      try {
        const data = await axios(
          `${BASE_URL}regist_memory`,
          "post",
          params,
          true
        );
        setResData(data);
        // state.isLoading = false;
        state.uploadFiles = [];
        // 並び順を変更している場合
        if (state.isSorted) {
          // 取得した画像一覧をソート済みid配列に従って並べ替え
          state.nur_memory.images = keywordSorter(
            "id",
            state.sortedIds,
            state.nur_memory.images
          );
        }
        state.isOpenSort = true;
        getSnack("画像を登録しました！");
      } catch (error) {
        // state.isLoading = false;
        state.uploadFiles = [];
        if (error.response?.data?.msg) {
          getSnack(error.response.data.msg, "error");
        } else {
          console.log("ERROR---@RegistAlbum/regist_memory/post", error);
        }
      }
    };

    /**
     * 表示順登録
     */
    const registSort = async () => {
      const params = {
        id: state.nur_memory.id,
        index_ids: state.sortedIds,
      };
      try {
        const data = await axios(`${BASE_URL}regist_memory`, "patch", params);
        setResData(data);
        state.isSorted = false;
        getSnack("表示順を登録しました！");
      } catch (error) {
        if (error.response?.data?.msg) {
          getSnack(error.response.data.msg, "error");
        } else {
          console.log("ERROR---@RegistAlbum/regist_memory/patch", error);
        }
      }
    };

    /**
     * 画像削除
     * @param img_id
     * @returns {Promise<void>}
     */
    const removeRow = async (img_id) => {
      try {
        const data = await axios(
          `${BASE_URL}regist_memory?id=${state.nur_memory.id}&image_id=${img_id}`,
          "delete"
        );
        setResData(data);
        // 並び順を変更している場合
        if (state.isSorted) {
          // ソート済みid配列から削除した画像idを除去
          state.sortedIds = state.sortedIds.filter((n) => n !== img_id);
          // 取得した画像一覧をソート済みid配列に従って並べ替え
          state.nur_memory.images = keywordSorter(
            "id",
            state.sortedIds,
            state.nur_memory.images
          );
        }
        getSnack("画像を削除しました！");
      } catch (error) {
        if (error.response?.data?.msg) {
          getSnack(error.response.data.msg, "error");
        } else {
          console.log("ERROR---@RegistAlbum/regist_memory/delete", error);
        }
      }
    };

    /**
     * 公開済み判定
     * @returns {string} 公開済み / 非公開
     */
    const isOpen = () => {
      return state.nur_memory.open_flg ? "公開済み" : "非公開";
    };

    /**
     * 公開状態変更
     * @returns {Promise<void>}
     */
    const changeOpenFlg = async () => {
      const params = {
        id: state.nur_memory.id,
        open_flg: state.nur_memory.open_flg,
      };
      try {
        await axios(`${BASE_URL}change_open`, "post", params);
        // 変更前の公開状態保持用フラグを更新
        state.beforeOpenState = state.nur_memory.open_flg;
        getSnack(`アルバムを${isOpen()}にしました！`);
      } catch (error) {
        state.nur_memory.open_flg = state.beforeOpenState;
        if (error.response?.data?.msg) {
          getSnack(error.response.data.msg, "error");
        } else {
          console.log("ERROR---@RegistAlbum/change_open/post", error);
        }
      }
    };

    /**
     * ガイド（並び替え後のidリスト）を元にオブジェクトをソート
     * @param key
     * @param guide
     * @param array
     * @returns {*}
     */
    const keywordSorter = (key, guide, array) => {
      // インデックスマップまたはオブジェクトを取得する
      const keyIndex = ((keys) => {
        if (typeof Map === "function") {
          return keys.reduce((map, a, idx) => {
            return map.set(a, idx);
          }, new Map());
        }
      })(guide);

      // ソート順を取得する
      const getSequenceNumber = (a) => {
        if (typeof Map === "function") {
          let seq = keyIndex.get(a[key]);
          return typeof seq !== "undefined" ? seq : array.length;
        }
      };

      // ソート結果を返す
      return array.sort((a, b) => {
        let seqA = getSequenceNumber(a);
        let seqB = getSequenceNumber(b);
        return seqA - seqB;
      });
    };

    return {
      state,
      classRules,
      getInitialData,
      changeClass,
      openDialog,
      dialogEvent,
      changeSort,
      registImages,
      registSort,
      removeRow,
      isOpen,
      changeOpenFlg,
    };
  },
};
</script>

<style scoped lang="scss">
.regist-area {
  border: 3px double #3fb48b;
  background-color: white;
  margin: 0;
  max-height: 150px;
  padding: 0;
}
.regist-area-changed {
  border: 3px double #f8b978;
  background-color: rgba(255, 244, 80, 0.4);
  margin: 0;
  max-height: 150px;
  padding: 0;
}
.regImgCard {
  border-color: #f8b978 !important;
}
.regSortCard {
  border-color: #fc6c85 !important;
}
.regImgTitle {
  color: #ffffff !important;
}
.handle {
  height: 100%;
  font-size: xxx-large;
  color: #3fb48b;
}
.fixedBtn {
  position: fixed;
  bottom: 5em;
  right: 0;
}
</style>
