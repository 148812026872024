<template>
  <v-dialog
    v-model="props.dialog"
    fullscreen
    :scrim="false"
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar color="member" class="white toolbar">
        <v-toolbar-title class="white title-text text-center">
          {{ props.childName }}
        </v-toolbar-title>
        <v-btn icon @click="closePreviewDialog()">
          <v-icon class="white">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-col cols="12" class="pa-5 header-space">
        <template v-for="category in state.categories" :key="category.id">
          <v-col cols="12" class="my-5">
            <v-row class="mx-0 px-0 mb-2">
              <p class="sub-color">
                {{ category.category_name }}
              </p>
              <v-divider color="admin"></v-divider>
            </v-row>
            <v-row class="mb-3 ml-1 mr-1 overflow-x-auto flex-nowrap">
              <template v-if="category.image_url.length === 0">
                <p class="sub-color mb-5">画像が登録されていません。</p>
              </template>
              <v-col
                v-for="(url, j) in category.image_url"
                :key="j"
                class="d-flex child-flex ma-0 pa-0"
                cols="4"
              >
                <v-col cols="12" class="flex-wrap ma-0 pa-0">
                  <v-img class="ma-1" :src="url" aspect-ratio="1" cover>
                    <v-overlay
                      activator="parent"
                      scroll-strategy="block"
                      class="align-center justify-center"
                    >
                      <v-img
                        :src="category.org_image_url[j]"
                        width="90vw"
                      ></v-img>
                    </v-overlay>
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey-lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <v-divider class="mx-5 mt-2 mb-1"></v-divider>
                  <div class="text-center ma-0 pa-0">
                    <v-chip
                      variant="text"
                      color="member"
                      @click="download(category.org_image_url[j])"
                    >
                      DL
                    </v-chip>
                  </div>
                </v-col>
              </v-col>
            </v-row>
          </v-col>
        </template>
      </v-col>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "@/plugins/axios.js";
import { reactive, onBeforeMount, watch, toRefs } from "vue";
import { BASE_URL } from "@/data";
import moment from "moment";

export default {
  name: "PreviewComponent",
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    childId: {
      type: Number,
      required: true,
    },
    childName: {
      type: String,
      required: true,
    },
  },
  emits: ["close_dialog"],
  setup(props, context) {
    const state = reactive({
      categories: [],
      childName: props.childName,
    });

    // 初期データ取得
    onBeforeMount(() => {
      getImages();
    });

    // 画像取得
    const getImages = async () => {
      state.isLoading = true;
      try {
        state.categories = await axios(
          `${BASE_URL}album_pictures?childId=${props.childId}`,
          "get"
        );
      } catch (error) {
        console.log("ERROR---@PrevImageView/album_pictures/get", error);
      }
    };

    /**
     * 画像(テンプレート)ダウンロード
     */
    const download = (url) => {
      const matches = url.match(/[A-Za-z0-9\-_]+\.\w+$/);
      fetch(url, {
        method: "GET",
        headers: {},
      })
        .then((response) => {
          response.arrayBuffer().then((buffer) => {
            const url = window.URL.createObjectURL(new Blob([buffer]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", matches[0]);
            document.body.appendChild(link);
            link.click();
          });
        })
        .catch((error) => {
          console.log("error", error);
        });
    };

    /**
     * 登録画像確認画面を閉じる
     */
    const closePreviewDialog = () => {
      state.isPreviewMode = false;
      context.emit("close_dialog", "previewDialog");
    };

    const { childId } = toRefs(props);
    watch(childId, () => {
      // 初期データ取得
      getImages();
    });

    return {
      state,
      closePreviewDialog,
      moment,
      props,
      download,
    };
  },
};
</script>

<style scoped lang="scss"></style>
