<template>
  <v-container v-if="props.isLoading">
    <span class="overlay" />
    <v-progress-circular
      :size="80"
      :width="7"
      color="officer"
      indeterminate
      class="spinner"
    ></v-progress-circular>
  </v-container>
</template>

<script>
export default {
  name: "SpinnerComponent",
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    return { props };
  },
};
</script>
<style lang="scss" scoped>
.overlay {
  position: fixed !important;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: #9b9b9b;
  opacity: 0.5;
  z-index: 90;
}
.spinner {
  position: absolute;
  top: 40%;
  left: 40%;
  z-index: 1000;
}
</style>
