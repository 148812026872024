<template>
  <v-container>
    <HeaderComponent />
    <v-col cols="12" class="mx-0 px-0">
      <TitleComponent title="園児一覧" />
      <!-- 登録ボタンは管理者のみ表示 -->
      <v-col class="end" v-if="state.isAdmin">
        <v-btn
          @click="state.registChildDialog = true"
          prepend-icon="mdi-plus-circle"
          rounded
          color="keyakiwakaba"
        >
          園児登録
        </v-btn>
      </v-col>
      <v-table class="children-table">
        <thead>
          <tr>
            <th class="text-center" colspan="2">
              <v-select
                class="pl-5"
                :items="langList"
                item-title="lang"
                item-value="val"
                v-model="state.selectNameLang"
                variant="underlined"
                hide-details
                single-line
                @update:modelValue="changeLangDisp"
              ></v-select>
            </th>
            <th class="text-center">詳細</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(child, i) in state.children" :key="i">
            <tr>
              <td class="text-center" style="width: 80px">
                <v-avatar
                  class="my-5"
                  :image="avatarUrl(child.avatar_image)"
                  size="50"
                ></v-avatar>
              </td>
              <td class="text-left text-no-wrap pr-0 sub-color">
                {{ state.dispNames[i] }}
              </td>
              <td class="text-center">
                <v-btn
                  @click="openChildrenDetail(child.id)"
                  size="small"
                  variant="outlined"
                  color="member"
                >
                  <p>詳細</p>
                </v-btn>
              </td>
            </tr>
          </template>
        </tbody>
      </v-table>
    </v-col>
  </v-container>
  <!-- 園児詳細 ダイアログ -->
  <ChildDetailComponent
    :dialog="state.childDetailDialog"
    @close_dialog="closeChildDialog"
    :childId="state.childId"
  />
  <RegistChildComponent
    :dialog="state.registChildDialog"
    @close_dialog="closeChildDialog"
  />
</template>

<script>
import { logout, avatarUrl } from "@/utility";
import HeaderComponent from "@/components/HeaderComponent.vue";
import TitleComponent from "@/components/TitleComponent.vue";
import ChildDetailComponent from "@/components/ChildDetailComponent.vue";
import RegistChildComponent from "@/components/RegistChildComponent.vue";
import { reactive } from "vue";
import axios from "@/plugins/axios.js";
import { BASE_URL } from "@/data";

export default {
  name: "ChildrenView",
  components: {
    TitleComponent,
    HeaderComponent,
    ChildDetailComponent,
    RegistChildComponent,
  },
  setup() {
    const userInfo = JSON.parse(sessionStorage.getItem("USER_INFO"));
    if (!userInfo) {
      logout();
    }
    const state = reactive({
      children: [],
      childDetailDialog: false,
      registChildDialog: false,
      childId: 0,
      isAdmin: false,
      selectNameLang: 0,
      dispNames: [],
    });

    const getInitialData = async () => {
      try {
        const data = await axios(`${BASE_URL}children`, "get");
        state.children = data.children;
        state.isAdmin = data.is_admin;
        changeLangDisp();
      } catch (e) {
        console.log("error", e);
      }
    };
    getInitialData();

    /**
     * 園児IDを受け取り、園児詳細ダイアログを開く
     * @param {Number} id 園児ID
     */
    const openChildrenDetail = (id) => {
      state.childId = id;
      state.childDetailDialog = true;
    };

    /**
     * 園児詳細画面の閉じるボタン押下時の処理
     */
    const closeChildDialog = (kind) => {
      getInitialData();
      state[kind] = false;
      if (state.childId != 0) state.childId = 0;
    };

    /**
     * 名前の表示言語切り替え
     */
    const changeLangDisp = () => {
      let nameList = [];
      let last = "";
      let first = "";
      if (state.selectNameLang === 0) {
        last = "last_name";
        first = "first_name";
      } else if (state.selectNameLang === 1) {
        last = "last_name_knj";
        first = "first_name_knj";
      } else if (state.selectNameLang === 2) {
        last = "last_name_eng";
        first = "first_name_eng";
      }
      for (const child of state.children) {
        if (child[last] !== null || child[first] !== null) {
          nameList.push(`${child[last]} ${child[first]}`);
        } else {
          nameList.push("未登録");
        }
      }
      state.dispNames = nameList;
    };

    return {
      userInfo,
      state,
      openChildrenDetail,
      closeChildDialog,
      avatarUrl,
      langList: [
        { lang: "名前(かな)", val: 0 },
        { lang: "名前(漢字)", val: 1 },
        { lang: "名前(ローマ字)", val: 2 },
      ],
      changeLangDisp,
    };
  },
};
</script>

<style scoped lang="scss">
th {
  color: #7e7e7e !important;
}
::v-deep(.v-table.children-table tbody tr:hover) {
  background-color: antiquewhite !important;
}
.avatar {
  border-radius: 50%;
}
</style>
