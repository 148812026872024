<template>
  <v-container>
    <HeaderComponent />
    <v-col cols="12" class="mx-0 px-0">
      <TitleComponent title="アルバム" />
      <v-select
        :items="state.nur_mst_grade"
        item-title="grade_name"
        item-value="id"
        v-model="state.class"
        label="クラス"
        variant="underlined"
        color="keyakiwakaba"
        @update:modelValue="getInitialData"
      ></v-select>
    </v-col>

    <v-row class="mx-0 px-0">
      <template v-if="state.class === ''">
        <v-sheet
          elevation="12"
          rounded="lg"
          height="40vh"
          width="90vw"
          class="pa-4 text-center mx-auto"
          color="rgba(255, 244, 80, 0.5)"
        >
          <v-col
            class="align-center d-flex flex-column justify-center"
            style="height: 100%"
          >
            <v-img
              max-height="200"
              width="200"
              v-bind:src="require('../assets/sushi.png')"
            ></v-img>
            <h2 class="text-h5 mb-6 text-disabled font-weight-bold">
              クラスを選択してください
            </h2>
          </v-col>
        </v-sheet>
      </template>
      <template v-else-if="state.nur_memory.images.length === 0">
        <v-sheet
          elevation="12"
          rounded="lg"
          height="40vh"
          width="90vw"
          class="pa-4 text-center mx-auto"
          color="rgba(255, 244, 80, 0.5)"
        >
          <v-col
            class="align-center d-flex flex-column justify-center"
            style="height: 100%"
          >
            <font-awesome-icon
              icon="shrimp"
              bounce
              style="color: #db5757; height: 150px; width: 150px"
            />
            <h2 class="text-h4 mb-6 text-disabled">Coming Soon!!!</h2>
          </v-col>
        </v-sheet>
      </template>
      <template v-else>
        <v-carousel
          v-model="state.page"
          class="album"
          show-arrows="hover"
          hide-delimiters
          height="70vh"
        >
          <v-carousel-item
            v-for="(image, i) in state.nur_memory.images"
            :key="`slideImages-${i}`"
            :value="i"
            :src="image"
            contain
          >
          </v-carousel-item>
        </v-carousel>
        <v-col cols="12" class="text-center">
          <v-btn
            variant="text"
            icon="mdi-chevron-double-left"
            @click="state.page = 0"
          ></v-btn>
          <v-btn
            variant="text"
            icon="mdi-minus"
            @click="state.page = Math.max(state.page - 1, 0)"
          ></v-btn>
          {{ state.page + 1 }} / {{ state.nur_memory.images.length }}
          <v-btn
            variant="text"
            icon="mdi-plus"
            @click="
              state.page = Math.min(
                state.page + 1,
                state.nur_memory.images.length - 1
              )
            "
          ></v-btn>
          <v-btn
            variant="text"
            icon="mdi-chevron-double-right"
            @click="state.page = state.nur_memory.images.length - 1"
          ></v-btn>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import { logout } from "@/utility";
import { reactive } from "vue";
import HeaderComponent from "@/components/HeaderComponent.vue";
import TitleComponent from "@/components/TitleComponent.vue";
import { BASE_URL } from "@/data";
import axios from "@/plugins/axios";

export default {
  name: "RegistAlbumView",
  components: { HeaderComponent, TitleComponent },
  display: "Transitions",
  computed: {},
  setup() {
    const userInfo = JSON.parse(sessionStorage.getItem("USER_INFO"));
    if (!userInfo) {
      logout();
    }

    const state = reactive({
      class: "",
      nur_mst_grade: [
        {
          id: null,
          grade_name: "",
        },
      ],
      nur_memory: { images: [] },
      page: 0,
    });

    /**
     * 初期表示
     * @returns {Promise<void>}
     */
    const getInitialData = async () => {
      try {
        const data = await axios(
          `${BASE_URL}memories?grade_id=${state.class}`,
          "get"
        );
        state.nur_mst_grade = data.nur_mst_grade;
        state.nur_memory.images = data.nur_memory.images;
      } catch (error) {
        alert(error);
      }
    };
    getInitialData();

    return {
      state,
      getInitialData,
    };
  },
};
</script>

<style scoped lang="scss">
.album {
  background-color: #efefef;
  box-shadow: 0 0 15px -5px #777777;
}
.navArrowBtn {
  opacity: 0.3;
}
</style>
