import { createRouter, createWebHistory } from "vue-router";
import LoginView from "@/views/LoginView";
import TopView from "@/views/TopView";
import SignUpView from "@/views/SignUpView";
import RegistStatusView from "@/views/RegistStatusView";
import CategoriesView from "@/views/CategoriesView";
import ImagesView from "@/views/ImagesView";
import ParentsView from "@/views/ParentsView";
import MyPageView from "@/views/MyPageView.vue";
import ChildrenView from "@/views/ChildrenView.vue";
import PasswordView from "@/views/PasswordView.vue";
import NoticeTemplate from "@/views/NoticeTemplate.vue";
import RegistAlbumView from "@/views/RegistAlbumView.vue";
import AlbumView from "@/views/AlbumView.vue";
import PrevImageView from "@/views/PrevImageView.vue";

const routes = [
  {
    // トップ画面
    path: "/",
    name: "top",
    component: TopView,
  },
  {
    // サインアップ画面
    path: "/signup",
    name: "signup",
    component: SignUpView,
  },
  {
    // ログイン画面
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    // 登録状況確認画面
    path: "/regist-status",
    name: "registStatus",
    component: RegistStatusView,
  },
  {
    // カテゴリ一覧・編集画面
    path: "/category",
    name: "category",
    component: CategoriesView,
  },
  {
    // 画像登録画面
    path: "/images",
    name: "images",
    component: ImagesView,
  },
  {
    // アルバム画面
    path: "/album",
    name: "album",
    component: AlbumView,
  },
  // {
  //   // TODO: 園児編集画面(ユーザ編集?)
  //   path: "/edit-child",
  //   name: "editChild",
  //   component: EditChildView,
  // },
  {
    // マイページ画面
    path: "/mypage",
    name: "mypage",
    component: MyPageView,
  },
  {
    // 保護者一覧・パスワードリセット画面
    path: "/parent",
    name: "parent",
    component: ParentsView,
  },
  {
    // 園児一覧画面
    path: "/children",
    name: "children",
    component: ChildrenView,
  },
  {
    // パスワード編集画面
    path: "/mypage/password",
    name: "password",
    component: PasswordView,
  },
  {
    // お知らせ画面
    path: "/notice",
    name: "notice",
    component: NoticeTemplate,
  },
  {
    // アルバム登録画面
    path: "/album/regist",
    name: "albumRegist",
    component: RegistAlbumView,
  },
  {
    // 登録画像確認画面
    path: "/images/preview",
    name: "prevImage",
    component: PrevImageView,
  },

  // TODO: history
  // TODO: 園児編集
  // 園児登録?
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
