<template>
  <v-container>
    <v-row class="text-center justify-center">
      <v-col cols="12">
        <!--タイトル -->
        <router-link :to="{ name: 'top' }">
          <p
            class="mt-15 title-text font-logo-en main-color"
            color="keyakiwakaba"
          >
            Keyakiwakaba Album <br />
            <span class="font-sub-en">LOGIN</span>
          </p>
        </router-link>
      </v-col>
      <!-- フォーム -->
      <v-col cols="10" class="mt-5">
        <v-form ref="form">
          <TextFieldComponent
            autofocus
            label="ニックネーム"
            v-model="state.nickname"
            type="text"
            refs="nickname"
            max="20"
          />
          <TextFieldComponent
            label="パスワード"
            v-model="state.password"
            type="password"
            refs="password"
            max="32"
            v-on:keydown.enter="login"
            autocomplete="off"
          />
          <p class="text-caption error" v-html="state.errMsg"></p>
          <v-btn color="keyakiwakaba" class="mt-5 font-sub-en" @click="login">
            LOGIN
          </v-btn>
        </v-form>
        <br />
        <router-link :to="{ name: 'signup' }">
          <p class="text-caption main-color mt-2">新規登録ページはこちら</p>
        </router-link>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { useRouter } from "vue-router";
import { reactive, ref } from "vue";
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import axios from "@/plugins/axios";
import { BASE_URL } from "@/data";
import { formatMsg } from "@/utility";

export default {
  name: "LoginView",
  components: { TextFieldComponent },
  setup() {
    const form = ref();
    const router = useRouter();
    const state = reactive({
      nickname: "",
      password: "",
      errMsg: "",
    });
    const login = async () => {
      const { valid } = await form.value.validate();
      state.errMsg = "";
      if (valid) {
        const params = {
          nickname: state.nickname,
          password: state.password,
        };
        try {
          const data = await axios(`${BASE_URL}login`, "post", params);
          const jwt = data.jwt;
          const userInfo = {
            nickname: data.nickname,
            token: jwt.token,
          };
          sessionStorage.setItem("USER_INFO", JSON.stringify(userInfo));
          // パスワードリセットの場合はパスワード変更ページへ飛ばす
          if (data.reset_flg) {
            router.push({ name: "password" });
          } else {
            router.push("/");
          }
        } catch (error) {
          if (
            error.response &&
            error.response.data &&
            error.response.data.msg
          ) {
            state.errMsg = formatMsg(error.response.data.msg);
          } else {
            console.log("ERROR---@Login/login/post", error);
          }
        }
      }
    };
    return {
      login,
      state,
      form,
    };
  },
};
</script>
