import axios from "axios";
import { BASE_URL } from "@/data";
import { logout } from "@/utility";

// TODO: エラー処理共通化
/**
 * HTTPリクエストを送る
 * @param {String} url
 * @param {String} method
 * @param {Object || Array} params
 * @param {Boolean} isFile
 * @returns data
 */
const AXIOS = async (url, method, params = null, isFile = false) => {
  return new Promise((response, reject) => {
    const contentType = isFile
      ? "multipart/form-data"
      : "application/json; charset=utf-8";
    const headers = {
      Accept: "*/*",
      "Content-Type": contentType,
    };
    const userInfo = JSON.parse(sessionStorage.getItem("USER_INFO"));
    if (userInfo) headers.Authorization = `Bearer ${userInfo.token}`;
    try {
      // 画像一括ダウンロードのみ別対応
      if (url === `${BASE_URL}download_zip`) {
        axios
          .get(`${url}?category_id=${params}`, {
            responseType: "blob",
            headers,
          })
          .then((res) => response(res))
          .catch((err) => reject(err));
        return;
      }
      switch (method) {
        case "get":
          axios
            .get(url, { headers })
            .then((res) => response(res.data))
            .catch((err) => {
              if (err.response.status == 401) {
                logout();
                return;
              }
              reject(err);
            });
          break;
        case "post":
          axios
            .post(url, params, { headers })
            .then((res) => response(res.data))
            .catch((err) => {
              if (err.response.status == 401 && !url.includes("login")) {
                logout();
                return;
              }
              reject(err);
            });
          break;
        case "patch":
          axios
            .patch(url, params, { headers })
            .then((res) => response(res.data))
            .catch((err) => {
              if (err.response.status == 401) {
                logout();
                return;
              }
              reject(err);
            });
          break;
        case "delete":
          axios
            .delete(url, { headers })
            .then((res) => response(res.data))
            .catch((err) => {
              if (err.response.status == 401) {
                logout();
                return;
              }
              reject(err);
            });
          break;
        default:
          break;
      }
    } catch (error) {
      console.log("ERROR---@axios", error);
    }
  });
};

export default AXIOS;
