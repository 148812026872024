<template>
  <DialogComponent
    :dialog="state.delConfDialog"
    :dialogInfo="DELETE_CONF_DIALOG_INFO"
    @dialog-event="dialogEvent"
  />
  <v-dialog
    v-model="props.dialog"
    fullscreen
    :scrim="false"
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar color="member" class="white toolbar">
        <v-toolbar-title class="white title-text text-center">
          お知らせ登録
        </v-toolbar-title>
        <v-btn icon @click="closeRegistDialog()">
          <v-icon class="white">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-col cols="10" class="align-self-center text-center header-space">
        <v-form ref="form">
          <TextFieldComponent
            label="タイトル"
            v-model="state.note.title"
            type="text"
            refs="title"
          />
          <v-textarea
            color="keyakiwakaba"
            label="内容"
            variant="outlined"
            rows="15"
            v-model="state.note.content"
          ></v-textarea>
          <div class="d-flex justify-space-around align-center preview-row">
            <template
              v-for="(temp, i) in state.note.template"
              :key="`temp_${i}`"
            >
              <v-col
                cols="5"
                :class="`${temp.saved ? 'saved' : 'unsaved'} preview-area`"
              >
                <v-img class="img" :src="temp.url" contain />
                <v-divider></v-divider>
                <p class="text-disabled">
                  {{ temp.saved ? "登録済み" : "未登録" }}
                </p>
                <v-btn
                  variant="text"
                  color="admin"
                  @click="
                    (state.delConfDialog = true), (state.selectTemplateIdx = i)
                  "
                  >削除</v-btn
                >
              </v-col>
            </template>
          </div>
          <v-file-input
            accept="image/png,image/jpeg"
            variant="underlined"
            multiple
            label="画像を選択"
            v-model:model-value="state.uploadFiles"
            v-on:change="addTemplate($event)"
          ></v-file-input>
          <v-btn
            class="mt-5"
            @click="submitNote(state.isEdit)"
            block
            color="keyakiwakaba"
          >
            <p>{{ state.isEdit ? "更新" : "登録" }}</p>
          </v-btn>
        </v-form>
      </v-col>
      <v-snackbar
        :timeout="2000"
        color="warning"
        v-model="state.snack.visible"
        rounded="pill"
        min-width="95%"
      >
        <p class="text-center">{{ state.snack.msg }}</p>
      </v-snackbar>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";
import axios from "@/plugins/axios";
import { reactive, ref, toRefs, watch } from "vue";
import { BASE_URL, DELETE_CONF_DIALOG_INFO } from "@/data";
import TextFieldComponent from "./TextFieldComponent.vue";
import DialogComponent from "@/components/DialogComponent.vue";
import { formatMsg } from "@/utility";

export default {
  name: "RegistNoticeComponent",
  components: { DialogComponent, TextFieldComponent },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    note: {
      type: Object,
      required: false,
    },
  },
  emits: ["close_dialog"],
  setup(props, context) {
    const form = ref();
    const state = reactive({
      note: {
        id: null,
        title: "",
        content: "",
        template: [],
      },
      uploadFiles: [],
      delConfDialog: false,
      selectTemplateIdx: null,
      snack: {
        visible: false,
        msg: "",
      },
      isEdit: false,
    });

    const { note } = toRefs(props);
    watch(note, () => {
      if (!note) {
        state.isEdit = false;
      } else {
        state.note = {
          ...props.note,
          content: props.note.body.replace(/<br \/>/g, "\n"),
        };
        Array.from(state.note.template).map((temp) => (temp.saved = true));
        state.isEdit = true;
      }
    });

    /**
     * ダイアログのボタンの処理分岐
     * @param {String} kinds ボタン種別
     */
    const dialogEvent = (kinds) => {
      switch (kinds) {
        // ** 削除確認ダイアログ **
        case "delete":
          removeTemplate();
          state.delConfDialog = false;
          break;
        case "quit":
        // 削除しないでもどる
        // fallthrough
        default:
          state.delConfDialog = false;
          state.selectTemplateIdx = null;
      }
    };

    /**
     * お知らせ登録画面を閉じる
     */
    const closeRegistDialog = (data = null, msg = "") => {
      state.note = {
        id: "",
        title: "",
        content: "",
        template: [],
      };
      state.uploadFiles = [];
      state.isEdit = false;
      if (data) {
        context.emit("close_dialog", data, msg);
      } else {
        context.emit("close_dialog");
      }
    };

    /**
     * 画像追加
     */
    const addTemplate = (e) => {
      const files = e.target.files;
      if (files?.length >= 1) {
        files.forEach((f) => {
          // 画像一時表示の為のURLを生成
          f.url = URL.createObjectURL(f);
          // 新規追加画像は未登録flgを追加
          f.saved = false;
          state.note.template.push(f);
        });
      }
      // 選択ファイルを初期化
      state.uploadFiles = [];
    };

    /**
     * 画像削除
     */
    const removeTemplate = async () => {
      const { note } = state;
      const delTemp = _.pullAt(note.template, state.selectTemplateIdx)[0];
      let msg = "画像を削除しました";
      if (delTemp.id) {
        try {
          const data = await axios(
            `${BASE_URL}image_destroy?id=${note.id}&template_id=${delTemp.id}`,
            "delete"
          );
          // データセット
          note.id = data.id;
          note.title = data.title;
          note.content = data.body.replace(/<br \/>/g, "\n");
          // note.template = data.template;
          // note.template.map((temp) => (temp.saved = true));
        } catch (error) {
          if (error.response?.data?.msg) {
            msg = "画像を削除に失敗しました";
          } else {
            console.log("ERROR---@RegistAlbum/regist_memory/delete", error);
          }
        }
      }
      state.selectTemplateIdx = null;
      state.snack = { visible: true, msg };
    };

    /**
     * \nを<br />に変換
     */
    const convertToHtml = (text) => {
      return text.replace(/\n/g, "<br />");
    };

    /**
     * お知らせ登録処理
     */
    const submitNote = async (isUpdate = false) => {
      const { valid } = await form.value.validate();
      if (valid) {
        try {
          const { note } = state;
          const paramImg = _.filter(note.template, ["saved", false]);
          const body = convertToHtml(note.content);
          const params = {
            id: note.id,
            title: note.title,
            body: body,
            page_template: paramImg,
          };
          const method = isUpdate ? "patch" : "post";
          const data = await axios(`${BASE_URL}note`, method, params, true);
          const msg = isUpdate ? "更新しました" : "登録しました";
          closeRegistDialog(data, msg);
        } catch (error) {
          if (error.response?.data?.msg) {
            state.errMsg = formatMsg(error.response.data.msg);
            closeRegistDialog();
          } else {
            console.log("ERROR---@RegistNoticeComponent/note", error);
          }
        }
      }
    };

    return {
      form,
      props,
      state,
      closeRegistDialog,
      addTemplate,
      dialogEvent,
      submitNote,
      DELETE_CONF_DIALOG_INFO,
    };
  },
};
</script>
<style lang="scss" scoped>
.img {
  height: 150px;
}
.preview-row {
  overflow-x: auto;
}
.preview-area {
  border: 3px double;
  margin: 0 5px;
  padding: 0;
  box-sizing: border-box;
}
.saved {
  border-color: #3fb48b;
  background-color: white;
}
.unsaved {
  border-color: #f8b978;
  background-color: rgba(255, 244, 80, 0.4);
}
</style>
