<template>
  <v-container>
    <HeaderComponent />
    <v-col cols="12" class="mx-0 px-0">
      <v-form ref="form">
        <TitleComponent title="パスワード変更" />
        <TextFieldComponent
          label="新規パスワード"
          v-model="state.nur_parent.password"
          type="password"
          refs="password"
          max="16"
        />
        <TextFieldComponent
          label="新規パスワード確認"
          v-model="state.nur_parent.password_confirmation"
          type="password"
          refs="password"
          max="16"
          :errorMsg="state.passwordErrMsg"
          v-on:keydown.enter="update"
        />
        <v-row justify="center">
          <v-btn
            color="keyakiwakaba"
            class="text-center font-sub-en mt-5"
            @click="update"
          >
            パスワードを変更
          </v-btn>
        </v-row>
        <br />
        <v-snackbar
          :timeout="2000"
          color="warning"
          v-model="state.snack.visible"
          rounded="pill"
          min-width="95%"
        >
          <p class="text-center">{{ state.snack.msg }}</p>
        </v-snackbar>
      </v-form>
    </v-col>
  </v-container>
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent.vue";
import TitleComponent from "@/components/TitleComponent.vue";
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import { useRouter } from "vue-router";
import { logout } from "@/utility";
import { reactive, ref } from "vue";
import { formatMsg } from "@/utility";
import axios from "@/plugins/axios";
import { BASE_URL } from "@/data";

export default {
  name: "PasswordView",
  components: { TitleComponent, HeaderComponent, TextFieldComponent },
  setup() {
    const userInfo = JSON.parse(sessionStorage.getItem("USER_INFO"));
    if (!userInfo) {
      logout();
    }
    const form = ref();
    const router = useRouter();
    const state = reactive({
      nur_parent: {
        password: "",
        password_confirmation: "",
      },
      errMsg: "",
      passwordErrMsg: "",
      snack: {
        visible: false,
      },
    });

    /**
     * パスワード確認
     */
    const checkPassword = () => {
      let isSamePass = false;
      if (
        !state.nur_parent.password ||
        !state.nur_parent.password_confirmation
      ) {
        isSamePass = false;
      } else if (
        state.nur_parent.password === state.nur_parent.password_confirmation
      ) {
        isSamePass = true;
        state.passwordErrMsg = "";
      } else {
        isSamePass = false;
        state.passwordErrMsg = "パスワードが異なります";
      }
      return isSamePass;
    };

    /**
     * パスワード変更処理
     */
    const update = async () => {
      const { valid } = await form.value.validate();
      state.errMsg = "";
      if (valid && checkPassword()) {
        try {
          const data = await axios(
            `${BASE_URL}change_password`,
            "patch",
            state.nur_parent
          );
          state.snack = {
            visible: true,
            msg: data.msg,
          };
          router.push("/mypage");
        } catch (error) {
          if (
            error.response &&
            error.response.data &&
            error.response.data.msg
          ) {
            state.errMsg = formatMsg(error.response.data.msg);
          } else {
            console.log("ERROR---@Password/update/patch", error);
          }
        }
      }
    };
    return {
      state,
      form,
      update,
    };
  },
};
</script>
