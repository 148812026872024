<template>
  <v-container>
    <v-row class="text-center justify-center">
      <v-col cols="12">
        <!--タイトル -->
        <p
          class="mt-15 title-text main-color font-logo-en"
          color="keyakiwakaba"
        >
          Keyakiwakaba Album <br />
          <span class="font-sub-en">SIGN UP</span>
        </p>
      </v-col>
      <!-- フォーム -->
      <SearchChildComponent />
      <v-col cols="10" class="mt-5">
        <p class="main-color">保護者情報登録</p>
        <v-form ref="form">
          <TextFieldComponent
            label="ニックネーム"
            v-model="state.nur_parent.nickname"
            type="text"
            refs="nickname"
            max="20"
          />
          <v-radio-group inline v-model:model-value="state.nur_parent.sex">
            <v-radio label="パパ" :value="0" color="keyakiwakaba"></v-radio>
            <v-radio label="ママ" :value="1" color="keyakiwakaba"></v-radio>
          </v-radio-group>
          <TextFieldComponent
            label="パスワード"
            v-model="state.nur_parent.password"
            type="password"
            refs="password"
            max="16"
          />
          <TextFieldComponent
            label="パスワード確認"
            v-model="state.nur_parent.password_confirmation"
            type="password"
            refs="password"
            max="16"
            :errorMsg="state.passwordErrMsg"
            v-on:keydown.enter="signup"
          />
          <p class="text-caption error" v-html="state.errMsg"></p>
          <v-btn color="keyakiwakaba" class="font-sub-en mt-5" @click="signup">
            SIGN UP
          </v-btn>
        </v-form>
        <br />
        <router-link :to="{ name: 'login' }">
          <p class="text-caption main-color mt-2">ログインページはこちら</p>
        </router-link>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "@/plugins/axios";
import { useRouter } from "vue-router";
import { reactive, ref } from "vue";
import { BASE_URL } from "@/data";
import { formatMsg } from "@/utility";
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import SearchChildComponent from "@/components/SearchChildComponent.vue";

export default {
  name: "LoginView",
  components: { TextFieldComponent, SearchChildComponent },
  setup() {
    const form = ref();
    const router = useRouter();
    const state = reactive({
      nur_parent: {
        nickname: "",
        sex: 0,
        password: "",
        password_confirmation: "",
      },
      errMsg: "",
      passwordErrMsg: "",
    });

    /**
     * パスワード確認
     */
    const checkPassword = () => {
      let isSamePass = false;
      if (
        !state.nur_parent.password ||
        !state.nur_parent.password_confirmation
      ) {
        isSamePass = false;
      } else if (
        state.nur_parent.password === state.nur_parent.password_confirmation
      ) {
        isSamePass = true;
        state.passwordErrMsg = "";
      } else {
        isSamePass = false;
        state.passwordErrMsg = "パスワードが異なります";
      }
      return isSamePass;
    };

    /**
     * サインアップ処理
     */
    const signup = async () => {
      const { valid } = await form.value.validate();
      state.errMsg = "";
      if (valid && checkPassword()) {
        try {
          const data = await axios(
            `${BASE_URL}signup`,
            "post",
            state.nur_parent
          );
          const jwt = data.jwt;
          const userInfo = {
            nickname: data.nickname,
            token: jwt.token,
          };
          sessionStorage.setItem("USER_INFO", JSON.stringify(userInfo));
          router.push("/");
        } catch (error) {
          if (
            error.response &&
            error.response.data &&
            error.response.data.msg
          ) {
            state.errMsg = formatMsg(error.response.data.msg);
          } else {
            console.log("ERROR---@SignUp/signup/post", error);
          }
        }
      }
    };
    return {
      state,
      form,
      signup,
    };
  },
};
</script>
