// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

// Vuetify
import { createVuetify } from "vuetify";

const myCustomTheme = {
  dark: false,
  colors: {
    keyakiwakaba: "#3FB48B",
    background: "#FFFFFF",
    primary: "#b1b1b1",
    secondary: "#03DAC6",
    error: "#fc6c85",
    info: "#2196F3",
    success: "#4CAF50",
    warning: "#FB8C00",
    member: "#F8B978",
    officer: "#FFF450",
    admin: "#FC6C85",
  },
};

export default createVuetify({
  theme: {
    defaultTheme: "myCustomTheme",
    themes: {
      myCustomTheme,
    },
  },
});
