<template>
  <p class="main-color text-center font-accent font-weight-bold mb-5">
    <span class="title">{{ title }}</span>
  </p>
</template>

<script>
export default {
  name: "TitleComponent",
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    return { props };
  },
};
</script>
<style lang="scss" scoped>
.title {
  font-size: 24px;
}
</style>
