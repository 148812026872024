<template>
  <v-container>
    <HeaderComponent />
    <v-col cols="12" class="mx-0 px-0">
      <TitleComponent title="保護者一覧" />
      <v-expansion-panels variant="accordion" multiple :value="1">
        <v-expansion-panel v-for="(child, i) in state.children" :key="i">
          <v-expansion-panel-title class="py-1 pane">
            <v-row no-gutters>
              <v-col cols="6" class="d-flex justify-start sub-color">
                {{ child.last_name }}&nbsp;{{ child.first_name }}
              </v-col>
              <v-col class="d-flex justify-start">
                <v-chip
                  variant="elevated"
                  v-if="
                    child.nur_parents[0] && child.nur_parents[0].auth_code !== 0
                  "
                  :color="
                    child.nur_parents[0]?.auth_code === 1 ? 'member' : 'admin'
                  "
                  size="small"
                  density="comfortable"
                  class="mr-1"
                >
                  {{
                    child.nur_parents[0]?.auth_code === 1 ? "役員" : "管理者"
                  }}
                </v-chip>
              </v-col>
            </v-row>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <v-list lines="one" class="py-0">
              <v-list-item
                density="compact"
                variant="plain"
                v-for="(parent, j) in child.nur_parents"
                :key="j"
                :title="parent.nickname"
                @click="selectedRecord(child, parent, i, j)"
              ></v-list-item>
            </v-list>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-container>
  <UpdateParentComponent
    :dialog="state.parentsUpdateDialog"
    @close_dialog="closeUpdateDialog"
    @parent_update="updateParent"
    :userInfo="userInfo"
  />
</template>

<script>
import { logout } from "@/utility";
import HeaderComponent from "@/components/HeaderComponent.vue";
import TitleComponent from "@/components/TitleComponent.vue";
import UpdateParentComponent from "@/components/UpdateParentComponent.vue";
import { reactive } from "vue";
import axios from "@/plugins/axios.js";
import { BASE_URL } from "@/data";
import { useStore } from "vuex";

export default {
  name: "ParentsView",
  components: { UpdateParentComponent, TitleComponent, HeaderComponent },
  setup() {
    const userInfo = JSON.parse(sessionStorage.getItem("USER_INFO"));
    if (!userInfo) {
      logout();
    }
    const store = useStore();
    const state = reactive({
      children: [
        {
          nur_parents: [
            {
              id: 0,
              nickname: "",
              sex: "",
              auth_code: 0,
              reset_flg: false,
              initial_password: "",
            },
          ],
        },
      ],
      parents: [
        {
          id: 0,
          nickname: "",
          sex: "",
          auth_code: 0,
          reset_flg: false,
          initial_password: "",
        },
      ],
      parentUpdateFlg: false,
      parentsUpdateDialog: false,
      selectedChildIdx: 0,
      selectedParentId: 0,
    });

    const getInitialData = async () => {
      try {
        const data = await axios(`${BASE_URL}parents`, "get");
        state.children = data;
        state.children.sort((a, b) => a.last_name.localeCompare(b.last_name));
        let records = [];
        state.children.forEach((child) => {
          child.nur_parents.forEach((parent) => {
            records.push(parent);
          });
        });
        state.parents = records;
        if (state.parentUpdateFlg) {
          await store.dispatch(
            "setParent",
            state.parents.find((parent) => parent.id === state.selectedParentId)
          );
        }
      } catch (e) {
        console.log("error", e);
      }
    };
    getInitialData();

    const closeUpdateDialog = () => {
      state.selectedChildIdx = 0;
      state.selectedParentId = 0;
      state.parentsUpdateDialog = false;
    };

    const selectedRecord = (child, parent) => {
      state.selectedParentId = parent.id;
      store.dispatch("setChild", child);
      store.dispatch("setParent", parent);
      state.parentsUpdateDialog = true;
    };

    const updateParent = () => {
      state.parentUpdateFlg = true;
      getInitialData();
    };

    return {
      userInfo,
      state,
      closeUpdateDialog,
      getInitialData,
      selectedRecord,
      updateParent,
    };
  },
};
</script>

<style scoped lang="scss">
th {
  color: #7e7e7e !important;
}
::v-deep(.v-table.parents-table tbody tr:hover) {
  background-color: antiquewhite !important;
}
.pane {
  min-height: 48px !important;
}
::v-deep(.v-expansion-panel-text__wrapper) {
  padding: 0 24px !important;
}
</style>
