<template>
  <v-app class="background">
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
};
</script>

<style lang="scss">
body {
  font-family: "Kiwi Maru";
  color: #7e7e7e;
}
a {
  text-decoration: none !important;
}
.error {
  color: #fc6c85;
}
.font-accent {
  font-family: "Rampart One";
}
.font-logo-en {
  font-family: "Sacramento";
}
.font-sub-en {
  font-family: "Righteous";
}
.end {
  text-align: end;
}
.start {
  text-align: start;
}
.main-color {
  color: #3fb48b !important;
}
.sub-color {
  color: #7e7e7e !important;
}
.white {
  color: #ffffff !important;
}
.member-color {
  color: #f8b978 !important;
}
.title-text {
  font-size: 24px;
}
.header-space {
  margin-top: 70px;
}
.toolbar {
  position: fixed !important;
  top: 0;
  z-index: 100;
}
</style>
