<template>
  <!-- 削除確認ダイアログ -->
  <DialogComponent
    :dialog="state.deleteConfDialog"
    :dialogInfo="DELETE_CONF_DIALOG_INFO"
    @dialog-event="dialogEvent"
  />
  <v-container>
    <HeaderComponent />
    <TitleComponent title="お知らせ" />
    <!-- 登録ボタンは管理者のみ表示 -->
    <v-col class="end" v-if="state.is_edit">
      <v-btn
        @click="state.registNoticeDialog = true"
        prepend-icon="mdi-plus-circle"
        rounded
        color="member"
      >
        <p class="white">お知らせ登録</p>
      </v-btn>
    </v-col>
    <v-row>
      <v-col cols="12">
        <template
          v-for="(note, i) in state.notice"
          :key="`notice_${note.id}_${i}`"
        >
          <v-card class="mt-5" variant="flat" color="white" elevation="3">
            <v-card-title class="member-color">
              {{ note.title }}
            </v-card-title>
            <v-divider></v-divider>
            <v-card-subtitle class="end sub-color">
              {{ note.i_date }}
            </v-card-subtitle>
            <v-card-text class="sub-color" v-html="note.body" />
            <v-card-actions class="end card-action" v-if="note.template">
              <v-spacer />
              <template
                v-for="(template, i) in note.template"
                :key="`note_temp_${i}`"
              >
                <v-spacer v-if="template.length === 1"></v-spacer>
                <v-img :src="template.url" class="img">
                  <v-overlay
                    activator="parent"
                    scroll-strategy="block"
                    class="align-center justify-center"
                  >
                    <v-img :src="template.url" width="90vw"></v-img>
                  </v-overlay>
                </v-img>
              </template>
              <v-icon
                v-if="note.template.length !== 0"
                @click="download(note.template)"
                icon="mdi-file-download-outline"
                size="x-large"
                color="admin"
                class="pa-5"
              ></v-icon>
            </v-card-actions>
            <v-card-actions class="end card-action" v-if="state.is_edit">
              <v-spacer />
              <v-btn
                @click="
                  {
                    state.deleteConfDialog = true;
                    state.selectNoteId = note.id;
                  }
                "
                color="error"
                variant="flat"
                size="small"
              >
                <p class="">削除</p>
              </v-btn>
              <v-btn
                color="keyakiwakaba"
                variant="flat"
                size="small"
                @click="editNote(note)"
              >
                編集
              </v-btn>
            </v-card-actions>
          </v-card>
          <v-snackbar
            :timeout="2000"
            color="warning"
            v-model="state.snack.visible"
            rounded="pill"
            min-width="95%"
          >
            <p class="text-center">{{ state.snack.msg }}</p>
          </v-snackbar>
        </template>
      </v-col>
    </v-row>
  </v-container>
  <RegistNoticeComponent
    :dialog="state.registNoticeDialog"
    :note="state.editNote"
    @close_dialog="closeNoticeDialog"
  />
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent.vue";
import TitleComponent from "@/components/TitleComponent.vue";
import RegistNoticeComponent from "@/components/RegistNoticeComponent.vue";
import DialogComponent from "@/components/DialogComponent.vue";
import axios from "@/plugins/axios.js";
import { BASE_URL, DELETE_CONF_DIALOG_INFO } from "@/data";
import { reactive } from "vue";

export default {
  name: "NoticeTemplate",
  components: {
    HeaderComponent,
    TitleComponent,
    RegistNoticeComponent,
    DialogComponent,
  },
  setup() {
    const state = reactive({
      is_edit: false,
      registNoticeDialog: false,
      notice: [],
      deleteConfDialog: false,
      selectNoteId: null,
      editNote: null,
      snack: {
        visible: false,
        msg: "",
      },
    });

    /**
     * 初期データ取得
     */
    const getInitialData = async () => {
      try {
        const data = await axios(`${BASE_URL}notice`, "get");
        state.notice = data.notice;
        state.is_edit = data.is_edit;
      } catch (error) {
        console.log("ERROR---@NoticeTemplate/notice/get", error);
      }
    };
    getInitialData();

    /**
     * 画像(テンプレート)ダウンロード
     */
    const download = (templates) => {
      const urls = templates.map((t) => t.url);
      urls.forEach((url) => {
        const matches = url.match(/[A-Za-z0-9\-_]+\.\w+$/);
        fetch(url, {
          method: "GET",
          headers: {},
        })
          .then((response) => {
            response.arrayBuffer().then((buffer) => {
              const url = window.URL.createObjectURL(new Blob([buffer]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", matches[0]);
              document.body.appendChild(link);
              link.click();
            });
          })
          .catch((error) => {
            console.log("error", error);
          });
      });
    };

    /**
     * ダイアログのボタンの処理分岐
     * @param {String} kinds ボタン種別
     */
    const dialogEvent = (kinds) => {
      switch (kinds) {
        // ** 削除確認ダイアログ **
        case "quit":
          // 削除しないでもどる
          state.deleteConfDialog = false;
          state.selectNoteId = null;
          break;
        case "delete":
          state.deleteConfDialog = false;
          deleteNotice();
          break;
      }
    };

    /**
     * 削除処理
     */
    const deleteNotice = async () => {
      try {
        const data = await axios(
          `${BASE_URL}note?id=${state.selectNoteId}`,
          "delete"
        );
        state.notice = data.notice;
        state.snack = {
          visible: true,
          msg: "削除しました！",
        };
        state.selectNoteId = null;
      } catch (error) {
        console.log("ERROR---@NoticeTemplate/note?id/delete", error);
      }
    };

    /**
     * 編集ダイアログを開く
     * @param {Number} id 編集対象ID
     */
    const editNote = (note) => {
      state.editNote = note;
      state.registNoticeDialog = true;
    };

    /**
     * お知らせ情報を再取得し、お知らせ登録画面を閉じる
     */
    const closeNoticeDialog = (data = null, msg = "") => {
      if (!data) {
        getInitialData();
      } else {
        state.notice = data.notice;
        state.snack = { visible: true, msg: msg };
      }
      state.registNoticeDialog = false;
    };

    return {
      DELETE_CONF_DIALOG_INFO,
      state,
      download,
      closeNoticeDialog,
      deleteNotice,
      dialogEvent,
      editNote,
    };
  },
};
</script>
<style lang="scss" scoped>
.img {
  height: 100px;
}
.title {
  border-bottom: 1px solid #ffffff;
}
.card-action {
  align-items: flex-end;
}
::v-deep(.mdi-plus-circle) {
  color: white;
}
::v-deep(.v-overlay__scrim) {
  opacity: 0.6;
}
</style>
