<template>
  <!-- 削除確認ダイアログ -->
  <DialogComponent
    :dialog="state.resetDialog"
    :dialogInfo="RESET_CONF_DIALOG_INFO"
    @dialog-event="dialogEvent"
  />
  <v-container>
    <v-col cols="12" class="create-child px-8">
      <div v-if="!state.search_result">
        <p class="main-color">園児検索</p>
        <p class="text-caption mb-5 main-color">
          子供の情報を入力して検索してね!
        </p>
        <v-form ref="search_form">
          <TextFieldComponent
            autofocus
            label="姓(ひらがな)"
            v-model="state.child_info.last_name"
            type="text"
            refs="last_name"
            max="20"
          />
          <TextFieldComponent
            label="名(ひらがな)"
            v-model="state.child_info.first_name"
            type="text"
            refs="first_name"
            max="20"
          />
          <TextFieldComponent
            label="生年月日"
            v-model="state.child_info.birthday"
            type="date"
            refs="birthday"
          />
          <p
            v-if="state.errMsg"
            class="text-caption error mb-2"
            v-html="state.errMsg"
          ></p>
          <v-btn
            color="keyakiwakaba"
            variant="outlined"
            class="font-sub-en"
            @click="search"
          >
            SEARCH
          </v-btn>
        </v-form>
      </div>
      <div v-else>
        <p class="text-caption sub-color mb-2">園児が見つかりました</p>
        <TitleComponent
          :title="`${state.child_info.last_name}
        ${state.child_info.first_name}`"
        />
        <div class="end">
          <v-btn
            color="keyakiwakaba"
            variant="outlined"
            class="font-sub-en"
            @click="state.resetDialog = true"
            size="x-small"
          >
            reset
          </v-btn>
        </div>
      </div>
    </v-col>
  </v-container>
</template>
<script>
import axios from "@/plugins/axios";
import { reactive, ref } from "vue";
import { BASE_URL, RESET_CONF_DIALOG_INFO } from "@/data";
import { formatMsg } from "@/utility";
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import TitleComponent from "./TitleComponent.vue";
import DialogComponent from "./DialogComponent.vue";

export default {
  name: "SearchChildComponent",
  components: { TextFieldComponent, TitleComponent, DialogComponent },
  setup() {
    const search_form = ref();
    const state = reactive({
      child_info: {
        last_name: "",
        first_name: "",
        birthday: "2020-04-01",
      },
      search_result: false,
      errMsg: "",
      resetDialog: false,
    });

    /**
     * 園児検索
     */
    const search = async () => {
      const { valid } = await search_form.value.validate();
      state.errMsg = "";
      if (valid) {
        try {
          const data = await axios(
            `${BASE_URL}/search_child`,
            "post",
            state.child_info
          );
          if (data.search_result) {
            state.search_result = data.search_result;
            const jwt = data.jwt;
            const userInfo = {
              token: jwt.token,
            };
            sessionStorage.setItem("USER_INFO", JSON.stringify(userInfo));
          }
        } catch (error) {
          if (
            error.response &&
            error.response.data &&
            error.response.data.msg
          ) {
            state.errMsg = formatMsg(error.response.data.msg);
          } else {
            console.log(
              "ERROR---@SearchChildComponent/search_child/post",
              error
            );
          }
        }
      }
    };

    /**
     * ダイアログイベント
     */
    const dialogEvent = (kinds) => {
      switch (kinds) {
        case "quit":
          // リセットせずに戻る
          state.resetDialog = false;
          break;
        case "reset":
          state.search_result = !state.search_result;
          state.resetDialog = false;
          break;
        default:
          break;
      }
    };
    return { state, search_form, search, RESET_CONF_DIALOG_INFO, dialogEvent };
  },
};
</script>
<style lang="scss" scoped>
.create-child {
  border: 3px double #7fad75;
  border-radius: 25px;
}
</style>
