<template>
  <template v-if="props.menuOption.title === 'カテゴリ登録'">
    <p class="permission-separate main-color mt-5">役員MENU</p>
  </template>
  <template v-if="props.menuOption.title === '保護者一覧'">
    <p class="permission-separate main-color mt-5">管理者MENU</p>
  </template>
  <v-col cols="12">
    <v-card
      :color="props.menuOption.theme"
      :prepend-icon="props.menuOption.icon"
    >
      <template v-slot:title>
        <p :class="props.menuOption.theme">
          {{ props.menuOption.title }}
        </p>
      </template>
      <v-card-subtitle :class="props.menuOption.theme">
        {{ props.menuOption.subtitle }}
      </v-card-subtitle>
      <v-card-actions class="justify-end">
        <v-btn
          variant="outlined"
          size="small"
          :class="props.menuOption.theme"
          @click="nextPage(props.menuOption.page)"
        >
          Go!!
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-col>
</template>

<script>
import router from "@/router";

export default {
  name: "NavigateCardComponent",
  props: {
    menuOption: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const nextPage = (page) => {
      router.push({ name: page });
    };

    return { props, nextPage };
  },
};
</script>

<style scoped lang="scss">
::v-deep(.mdi-image-edit-outline),
::v-deep(.mdi-account-child-outline),
::v-deep(.mdi-message-alert-outline),
::v-deep(.mdi-human-male-child),
::v-deep(.mdi-information-outline),
::v-deep(.mdi-book-open-page-variant-outline) {
  font-size: 1.25rem;
  color: white;
}
::v-deep(.mdi-tag-plus-outline),
::v-deep(.mdi-checkbox-marked-outline),
::v-deep(.mdi-book-open-blank-variant),
::v-deep(.mdi-image-search-outline) {
  font-size: 1.25rem;
  color: #3fb48b;
}

::v-deep(div.v-card-title) {
  font-size: 1rem;
}
::v-deep(.v-card-item) {
  padding: 0;
}
::v-deep(.v-card-item__prepend) {
  padding: 0;
}

.permission-separate {
  display: flex;
  align-items: center;
  font-size: 20px;
}
.permission-separate:before,
.permission-separate:after {
  content: "";
  height: 1px;
  flex-grow: 1;
  background-color: #3fb48b;
}
.permission-separate:before {
  margin-right: 1rem;
}
.permission-separate:after {
  margin-left: 1rem;
}
.member {
  color: #ffffff;
}
.officer {
  color: #3fb48b;
}
.admin {
  color: #ffffff;
}
</style>
