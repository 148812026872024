<template>
  <v-dialog
    v-model="props.dialog"
    fullscreen
    :scrim="false"
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar color="member" class="white toolbar">
        <v-toolbar-title class="white title-text text-center">
          園児登録
        </v-toolbar-title>
        <v-btn icon @click="closeRegistDialog()">
          <v-icon class="white">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-col cols="10" class="align-self-center text-center header-space">
        <v-form ref="form">
          <TextFieldComponent
            label="姓(ひらがな)"
            v-model="state.childInfo.last_name"
            type="text"
            refs="last_name"
            max="20"
          />
          <TextFieldComponent
            label="名(ひらがな)"
            v-model="state.childInfo.first_name"
            type="text"
            refs="first_name"
            max="20"
          />
          <v-select
            :items="SELECT_SEX"
            item-title="sex"
            v-model="state.childInfo.sex"
            label="性別"
            variant="underlined"
            color="keyakiwakaba"
            :rules="sexRules"
          ></v-select>
          <TextFieldComponent
            label="生年月日"
            v-model="state.childInfo.birthday"
            type="date"
            refs="birthday"
          />
          <v-btn class="mt-5" @click="registChild()" block color="keyakiwakaba">
            <p>登録</p>
          </v-btn>
        </v-form>
      </v-col>
    </v-card>
    <v-snackbar
      :timeout="2000"
      color="warning"
      v-model="state.snack.visible"
      rounded="pill"
      min-width="95%"
    >
      <p class="text-center">{{ state.snack.msg }}</p>
    </v-snackbar>
  </v-dialog>
</template>

<script>
import { reactive, ref } from "vue";
import router from "@/router";
import axios from "@/plugins/axios";
import { formatMsg } from "@/utility";
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import { SELECT_SEX, BASE_URL } from "@/data";
import { sexRules } from "@/validationRules";

export default {
  name: "RegistChildComponent",
  components: { TextFieldComponent },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["close_dialog"],
  setup(props, context) {
    const form = ref();
    const state = reactive({
      childInfo: {
        last_name: "",
        first_name: "",
        sex: "",
        birthday: "2020-04-01",
      },
      snack: {
        visible: false,
        msg: "",
      },
    });

    /**
     * 園児登録画面を閉じる
     */
    const closeRegistDialog = () => {
      context.emit("close_dialog", "registChildDialog");
    };

    /**
     * 園児登録
     */
    const registChild = async () => {
      const { valid } = await form.value.validate();
      if (valid) {
        try {
          await axios(`${BASE_URL}child`, "post", state.childInfo);
          state.snack = {
            visible: true,
            msg: "登録しました",
          };
          closeRegistDialog();
        } catch (error) {
          if (
            error.response &&
            error.response.data &&
            error.response.data.msg
          ) {
            state.errMsg = formatMsg(error.response.data.msg);
          } else {
            console.log("ERROR---@RegistChildComponent/child/post", error);
          }
        }
      }
    };

    return {
      props,
      state,
      router,
      closeRegistDialog,
      registChild,
      SELECT_SEX,
      form,
      sexRules,
    };
  },
};
</script>

<style scoped lang="scss">
::v-deep(.v-card--variant-outlined) {
  border: solid 1px #3fb48b;
}
::v-deep(.bg-primary.btn-cancel) {
  color: white !important;
}
</style>
