<template>
  <v-container>
    <HeaderComponent />
    <v-col cols="12" class="mx-0 px-0">
      <TitleComponent title="登録画像確認" />
      <v-table density="compact" class="children-table">
        <thead>
          <tr>
            <th></th>
            <th>名前</th>
            <th class="text-center">確認</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(child, i) in state.children" :key="i">
            <tr>
              <td class="text-center" style="width: 80px">
                <v-avatar
                  class="my-1"
                  :image="avatarUrl(child.avatar_image)"
                  size="40"
                ></v-avatar>
              </td>
              <td class="text-left text-no-wrap pr-0 sub-color">
                {{ child.last_name }}&nbsp;{{ child.first_name }}
              </td>
              <td class="text-center">
                <v-btn
                  icon="mdi-chevron-double-right"
                  size="small"
                  variant="outlined"
                  color="member"
                  @click="
                    openPreviewDialog(
                      child.id,
                      child.last_name,
                      child.first_name
                    )
                  "
                ></v-btn>
              </td>
            </tr>
          </template>
        </tbody>
      </v-table>
    </v-col>
  </v-container>
  <!-- 園児詳細 ダイアログ -->
  <PreviewComponent
    :dialog="state.previewDialog"
    @close_dialog="closePreviewDialog"
    :childId="state.childId"
    :childName="state.childName"
  />
</template>

<script>
import { logout, avatarUrl } from "@/utility";
import HeaderComponent from "@/components/HeaderComponent.vue";
import TitleComponent from "@/components/TitleComponent.vue";
import PreviewComponent from "@/components/PreviewComponent.vue";
import { reactive } from "vue";
import axios from "@/plugins/axios.js";
import { BASE_URL } from "@/data";

export default {
  name: "PrevImageView",
  components: {
    TitleComponent,
    HeaderComponent,
    PreviewComponent,
  },
  setup() {
    const userInfo = JSON.parse(sessionStorage.getItem("USER_INFO"));
    if (!userInfo) {
      logout();
    }
    const state = reactive({
      children: [],
      previewDialog: false,
      childId: 0,
      childName: "",
    });

    const getInitialData = async () => {
      try {
        const data = await axios(`${BASE_URL}children`, "get");
        state.children = data.children;
      } catch (e) {
        console.log("error", e);
      }
    };
    getInitialData();

    /**
     * 園児IDを受け取り、登録画像確認ダイアログを開く
     * @param {Number} id 園児ID
     */
    const openPreviewDialog = (id, lName, fName) => {
      state.childId = id;
      state.childName = `${lName} ${fName}`;
      state.previewDialog = true;
    };

    /**
     * 登録画像確認ダイアログの閉じるボタン押下時の処理
     */
    const closePreviewDialog = (kind) => {
      getInitialData();
      state[kind] = false;
      if (state.childId !== 0) state.childId = 0;
      if (state.childName !== "") state.childName = "";
    };

    return {
      userInfo,
      state,
      openPreviewDialog,
      closePreviewDialog,
      avatarUrl,
    };
  },
};
</script>

<style scoped lang="scss">
th {
  color: #7e7e7e !important;
}
::v-deep(.v-table.children-table tbody tr:hover) {
  background-color: antiquewhite !important;
}
.avatar {
  border-radius: 50%;
}
::v-deep(.v-btn--size-small) {
  --v-btn-height: 15px;
}
</style>
