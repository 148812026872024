<template>
  <!-- DL確認ダイアログ -->
  <DialogComponent
    :dialog="state.downloadConfDialog"
    :dialogInfo="DOWNLOAD_CONF_DIALOG_INFO"
    @dialog-event="dialogEvent"
  />
  <v-container>
    <HeaderComponent />
    <TitleComponent title="登録状況一覧" />
    <v-col v-if="state.children.length === 0" class="sub-color">
      カテゴリが登録されていません。
    </v-col>
    <v-col v-else cols="12" class="mx-0 px-0">
      <v-select
        :items="state.categories"
        item-title="name"
        item-value="id"
        v-model="state.select"
        label="カテゴリー"
        variant="underlined"
        color="keyakiwakaba"
        @update:modelValue="sortCategory"
      ></v-select>
      <span class="text-body-2 error date-area">
        期限:
        {{
          state.categories.find((c) => c.id === state.select)
            ? moment(
                state.categories.find((c) => c.id === state.select).dead_date
              ).format("YYYY年MM月DD日")
            : ""
        }}</span
      >
      <div class="mb-1 mt-0 pt-0 text-end">
        <v-btn
          size="small"
          color="member"
          class="font-weight-bold"
          @click="downloadDialog"
        >
          <p class="white">画像一括ダウンロード</p>
        </v-btn>
      </div>
      <v-table fixed-header height="70vh" hover>
        <thead>
          <tr>
            <th></th>
            <th class="text-left py-0">名前</th>
            <th class="text-center pa-0">登録状況</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(child, i) in state.sortedChildren" :key="i">
            <td class="sub-color pa-0 ma-0" style="width: 80px">
              <v-avatar
                class="ma-2"
                :image="avatarUrl(child.avatar_image)"
                size="70"
              ></v-avatar>
            </td>
            <td class="sub-color">
              {{ child.child_name }}
            </td>
            <td class="text-center">
              {{
                child.is_registered.find((r) => r.category_id === state.select)
                  ? child.is_registered.find(
                      (r) => r.category_id === state.select
                    ).is_regist
                    ? "○"
                    : "×"
                  : "×"
              }}
            </td>
          </tr>
        </tbody>
      </v-table>
    </v-col>
  </v-container>
</template>

<script>
import { avatarUrl } from "@/utility";
import HeaderComponent from "@/components/HeaderComponent.vue";
import TitleComponent from "@/components/TitleComponent.vue";
import { reactive } from "vue";
import axios from "@/plugins/axios.js";
import { BASE_URL, DOWNLOAD_CONF_DIALOG_INFO } from "@/data";
import DialogComponent from "@/components/DialogComponent.vue";
import moment from "moment";
import lodash from "lodash";

export default {
  name: "RegistStatusView",
  components: { DialogComponent, HeaderComponent, TitleComponent },
  setup() {
    const state = reactive({
      select: null,
      categories: [],
      children: [],
      downloadConfDialog: false,
      sortedChildren: [],
    });

    const getInitialData = async () => {
      try {
        const data = await axios(`${BASE_URL}regist_state`, "get");
        if (data.categories.length !== 0) {
          state.select = data.categories[0].id;
          state.categories = data.categories;
          state.children = data.children;
          sortCategory();
        }
      } catch (e) {
        alert(e);
      }
    };
    getInitialData();

    /**
     * カテゴリー選択時、 × を上に園児を並び替える
     */
    const sortCategory = () => {
      const children = lodash.cloneDeep(state.children);
      // ×を上に並び替え
      state.sortedChildren = children.sort(
        (a, b) =>
          Number(
            a.is_registered.find((r) => r.category_id === state.select)
              .is_regist
          ) -
          Number(
            b.is_registered.find((r) => r.category_id === state.select)
              .is_regist
          )
      );
    };

    /**
     * 登録画像一括ダウンロード
     */
    const downloadImages = async () => {
      try {
        const res = await axios(`${BASE_URL}download_zip`, "get", state.select);
        // headerからファイル名を取得
        let fileName = res.headers["content-disposition"]
          .split("filename=")[1]
          .split(";")[0]
          .replaceAll(/(^")|("$)/g, "");
        // downloadLinkを作成
        const blob = new Blob([res.data], { type: "application/zip" });
        const a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        fileName = decodeURI(decodeURI(fileName));
        a.download = fileName;
        a.click();
        URL.revokeObjectURL(a.href);
        await deleteTempFile(fileName);
      } catch (e) {
        alert(e);
      }
    };

    /** 一時ファイル削除
     * @param fileName ファイル名
     */
    const deleteTempFile = async (fileName) => {
      const params = {
        file_name: fileName,
      };
      await axios(`${BASE_URL}delete_zip_file`, "post", params);
    };

    /**
     * カテゴリー削除ダイアログの有無を判定し、表示させる
     */
    const downloadDialog = () => {
      state.downloadConfDialog = true;
    };

    /**
     * ダイアログのボタンの処理分岐
     * @param {String} kinds ボタン種別
     */
    const dialogEvent = (kinds) => {
      switch (kinds) {
        case "quit":
          // DLしないでもどる
          state.downloadConfDialog = false;
          break;
        case "download":
          state.downloadConfDialog = false;
          downloadImages();
          break;
        default:
          break;
      }
    };

    return {
      state,
      avatarUrl,
      sortCategory,
      getInitialData,
      downloadImages,
      dialogEvent,
      downloadDialog,
      DOWNLOAD_CONF_DIALOG_INFO,
      moment,
    };
  },
};
</script>

<style scoped lang="scss">
.avatar {
  border-radius: 50%;
}
.date-area {
  position: relative;
  top: -20px;
}
</style>
