<template>
  <!-- 削除確認ダイアログ -->
  <DialogComponent
    :dialog="state.deleteConfDialog"
    :dialogInfo="DELETE_CONF_DIALOG_INFO"
    @dialog-event="dialogEvent"
  />
  <!-- 保存確認ダイアログ -->
  <DialogComponent
    :dialog="state.confDialog"
    :dialogInfo="SAVE_CATEGORY_CONF_DIALOG_INFO"
    @dialog-event="dialogEvent"
  />
  <!-- カテゴリー登録画面 -->
  <v-dialog
    v-model="props.dialog"
    fullscreen
    :scrim="false"
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar color="member" class="white toolbar">
        <v-toolbar-title class="white title-text text-center">
          カテゴリー登録
        </v-toolbar-title>
        <v-btn icon>
          <v-icon class="white" @click="closeRegistDialog">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-col cols="12" class="pa-5 header-space">
        <TitleComponent :title="state.className" />
        <!-- 登録フィールド -->
        <v-form ref="form">
          <template
            v-for="(category, i) in state.categories"
            :key="category.id"
          >
            <!-- カテゴリ枠 - 更新がある場合はクラスを変更する -->
            <v-col
              :class="
                category.updateFlg
                  ? 'update-category-box sub-color'
                  : 'category-box sub-color'
              "
            >
              <v-row class="d-flex flex-row">
                <v-col class="start py-0">
                  <v-switch
                    v-model="category.is_officer_only"
                    color="keyakiwakaba"
                    label="役員用"
                    hide-details
                    class="px-0 py-0"
                    @update:modelValue="
                      confUpdate($event, category.id, 'is_officer_only'),
                        changeOfficerOnlyFlg($event, category.id)
                    "
                  ></v-switch>
                </v-col>
                <v-col class="end">
                  <!-- 削除アイコン - ラス1で初期じゃなければ削除ボタン出す -->
                  <v-icon
                    icon="mdi-delete-circle-outline"
                    @click="deleteCategoryDialog(i)"
                    color="error"
                  ></v-icon>
                </v-col>
              </v-row>
              <TextFieldComponent
                label="カテゴリ名"
                v-model="category.page_category"
                type="text"
                refs="page_category"
                max="20"
                @update:modelValue="
                  confUpdate($event, category.id, 'page_category')
                "
                @click:clear="confUpdate($event, category.id, 'page_category')"
              />
              <v-row v-if="!category.is_officer_only">
                <v-col cols="7">
                  <TextFieldComponent
                    label="期限"
                    v-model="category.dead_date"
                    type="date"
                    refs="dead_date"
                    @update:modelValue="
                      confUpdate($event, category.id, 'dead_date')
                    "
                    @click:clear="confUpdate($event, category.id, 'dead_date')"
                  />
                </v-col>
                <v-col cols="5">
                  <TextFieldComponent
                    label="枚数"
                    v-model="category.need_qty"
                    type="number"
                    refs="need_qty"
                    @update:modelValue="
                      confUpdate($event, category.id, 'need_qty')
                    "
                    @click:clear="confUpdate($event, category.id, 'need_qty')"
                  />
                </v-col>
              </v-row>
              <p class="Caption py-0" v-if="category.is_officer_only">
                ※役員以外には表示されません
              </p>
            </v-col>
          </template>
          <p class="text-caption error" v-html="state.errMsg"></p>
          <v-row class="mt-5">
            <v-col align-self="end" class="end">
              <v-btn
                color="keyakiwakaba"
                variant="outlined"
                @click="addCategory"
              >
                カテゴリ追加
              </v-btn>
              <v-btn color="keyakiwakaba" @click="submit" class="ml-3">
                登録
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
        <v-snackbar
          :timeout="2000"
          :color="state.snack.color"
          v-model="state.snack.visible"
          rounded="pill"
          min-width="95%"
        >
          <p class="text-center">{{ state.snack.msg }}</p>
        </v-snackbar>
      </v-col>
    </v-card>
  </v-dialog>
</template>

<script>
import TitleComponent from "./TitleComponent.vue";
import TextFieldComponent from "./TextFieldComponent.vue";
import DialogComponent from "./DialogComponent.vue";
import { computed, reactive, ref } from "vue";
import {
  BASE_URL,
  DELETE_CONF_DIALOG_INFO,
  SAVE_CATEGORY_CONF_DIALOG_INFO,
} from "@/data";
import { useStore } from "vuex";
import axios from "@/plugins/axios";
import lodash from "lodash";

export default {
  name: "CategoryRegistComponent",
  components: { TitleComponent, TextFieldComponent, DialogComponent },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["close_dialog", "update_categories"],
  setup(props, context) {
    const store = useStore();
    const initialCategory = {
      page_category: "",
      need_qty: 1,
      dead_date: "",
      updateFlg: true,
      is_officer_only: false,
    };
    const state = reactive({
      deleteConfDialog: false,
      deleteCategoryIndex: null,
      className: "",
      initialCategories: [],
      categories: [],
      errMsg: "",
      snack: {
        visible: false,
        msg: "",
        color: "",
      },
      confDialog: false,
    });
    const form = ref();

    /**
     * 受け取った data から初期データを作成
     * @param {Array} data カテゴリー
     */
    const createInitialData = (data) => {
      // 比較用に初期取得カテゴリーを設定
      state.initialCategories = lodash.cloneDeep(data);
      // 取得したカテゴリーを設定(データがない場合は初期値を追加)
      if (data.length == 0) {
        state.categories = [lodash.cloneDeep(initialCategory)];
      } else {
        state.categories = lodash.cloneDeep(data);
        // updateFlg を追加
        state.categories.map((c) => (c.updateFlg = false));
      }
    };

    /**
     * 初期データ取得
     */
    const getInitialData = async () => {
      try {
        const data = await axios(`${BASE_URL}category`, "get");
        createInitialData(data);
      } catch (error) {
        console.log("ERROR---@CategoryRegistComponent/category/get", error);
      }
    };

    getInitialData();

    state.className = computed(() => {
      return store.getters.getClassName;
    });

    /**
     * ダイアログのボタンの処理分岐
     * @param {String} kinds ボタン種別
     */
    const dialogEvent = (kinds) => {
      switch (kinds) {
        case "quit":
          // 削除しないでもどる
          state.deleteConfDialog = false;
          state.deleteCategoryIndex = null;
          break;
        case "delete":
          state.deleteConfDialog = false;
          deleteCategory();
          break;
        case "back":
          state.confDialog = false;
          break;
        case "close":
          state.confDialog = false;
          closeRegist();
          break;
        default:
          break;
      }
    };

    /**
     * カテゴリー数追加
     */
    const addCategory = () => {
      const category = lodash.cloneDeep(initialCategory);
      state.categories.push(category);
    };

    /**
     * カテゴリーの削除
     */
    const deleteCategory = async () => {
      if (state.categories[state.deleteCategoryIndex].id) {
        try {
          const data = await axios(
            `${BASE_URL}category?id=${
              state.categories[state.deleteCategoryIndex].id
            }`,
            "delete"
          );
          state.snack = {
            visible: true,
            msg: "削除しました!",
            color: "warning",
          };
          // 初期値の更新
          createInitialData(data);
        } catch (error) {
          if (
            error.response &&
            error.response.data &&
            error.response.data.msg
          ) {
            state.snack = {
              visible: true,
              msg: error.response.data.msg,
              color: "error",
            };
          } else {
            console.log(
              "ERROR---@CategoryRegistComponent/category/delete",
              error
            );
          }
        }
      } else {
        state.categories.splice(state.deleteCategoryIndex, 1);
      }
      state.deleteCategoryIndex = null;
      if (state.categories.length === 0) {
        addCategory();
      }
    };

    /**
     * カテゴリー削除ダイアログの有無を判定し、表示させる
     * @param {Integer} index 削除するカテゴリーのindex
     */
    const deleteCategoryDialog = (index) => {
      if (lodash.isEqual(state.categories[index], initialCategory)) {
        state.categories.splice(index, 1);
      } else {
        state.deleteCategoryIndex = index;
        state.deleteConfDialog = true;
      }
    };

    /**
     * 登録処理
     */
    const submit = async () => {
      const { valid } = await form.value.validate();
      state.errMsg = "";
      if (valid) {
        try {
          // 更新があるもののみ取得(updateFlg)
          const categories = lodash.filter(state.categories, [
            "updateFlg",
            true,
          ]);
          if (categories.length <= 0) {
            state.snack = {
              visible: true,
              msg: "すでに登録されています!",
              color: "error",
            };
            return;
          }
          const params = { categories };
          const data = await axios(`${BASE_URL}category`, "post", params);
          state.snack = {
            visible: true,
            msg: "登録しました!",
            color: "warning",
          };
          createInitialData(data);
        } catch (error) {
          console.log("ERROR---@CategoryRegistComponent/category/post", error);
        }
      }
    };

    /**
     * 初期値と比較して更新が発生しているかを確認する
     * @param {String} value - 入力内容
     * @param {Integer} id - 編集したカテゴリーID
     * @param {String} kinds - 項目名
     */
    const confUpdate = (value, id, kinds) => {
      const initial = state.initialCategories.find((c) => c.id === id);
      if (initial) {
        state.categories.find((c) => c.id === id).updateFlg =
          initial[kinds] != value;
      }
    };

    /**
     * カテゴリ登録画面を閉じる
     */
    const closeRegist = () => {
      context.emit("update_categories");
      context.emit("close_dialog");
    };

    /**
     * カテゴリ登録画面の閉じるボタン押下時
     * 編集中のカテゴリがあるかを確認しダイアログを表示させる
     */
    const closeRegistDialog = () => {
      if (lodash.find(state.categories, { updateFlg: true })) {
        state.confDialog = true;
      } else {
        closeRegist();
      }
    };

    const changeOfficerOnlyFlg = (value, id) => {
      const cate = state.categories.find((c) => c.id === id);
      if (value) {
        cate.dead_date = "2030-12-31";
        cate.need_qty = 100;
        cate.page_category = cate.page_category.startsWith("【役員用】")
          ? cate.page_category
          : `【役員用】${cate.page_category}`;
      } else {
        initialize(id);
      }
    };

    const initialize = (id) => {
      const initCate = state.initialCategories.find((c) => c.id === id);
      const category = state.categories.find((c) => c.id === id);
      if (!initCate) {
        category.page_category = "";
        category.dead_date = "";
        category.need_qty = 1;
        return;
      }

      category.page_category = initCate["page_category"].startsWith(
        "【役員用】"
      )
        ? initCate["page_category"].substring(5)
        : initCate["page_category"];
      category.dead_date =
        initCate["dead_date"] === "2030-12-31" ? "" : initCate["dead_date"];
      category.need_qty =
        initCate["need_qty"] === 100 ? 1 : initCate["need_qty"];
    };

    return {
      DELETE_CONF_DIALOG_INFO,
      SAVE_CATEGORY_CONF_DIALOG_INFO,
      state,
      props,
      submit,
      addCategory,
      deleteCategory,
      dialogEvent,
      initialCategory,
      confUpdate,
      lodash,
      form,
      deleteCategoryDialog,
      closeRegistDialog,
      changeOfficerOnlyFlg,
    };
  },
};
</script>

<style scoped lang="scss">
.category-box {
  border: double 3px #3fb48b;
  margin: 15px 0;
}
.update-category-box {
  border: double #f8b978 3px;
  margin: 15px 0;
  background-color: rgba(255, 244, 80, 0.4);
  color: #7e7e7e;
}
</style>
