<template>
  <v-dialog
    v-model="props.dialog"
    fullscreen
    :scrim="false"
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar color="member" class="white toolbar">
        <v-toolbar-title class="white title-text text-center">
          保護者情報編集
        </v-toolbar-title>
        <v-btn icon>
          <v-icon class="white" @click="closeUpdateDialog">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-col cols="12" class="pa-5 header-space">
        <!-- 登録フィールド -->
        <v-form ref="form">
          <v-table>
            <tbody>
              <tr>
                <td class="row-header">園児名</td>
                <td class="separate">:</td>
                <td class="text-medium-emphasis">
                  {{ state.child.last_name + " " + state.child.first_name }}
                </td>
              </tr>
              <tr>
                <td class="row-header">性別</td>
                <td class="separate">:</td>
                <td class="text-medium-emphasis">{{ state.parent.sex }}</td>
              </tr>
              <tr>
                <td class="row-header">保護者名</td>
                <td class="separate">:</td>
                <td class="text-medium-emphasis">
                  {{ state.parent.nickname }}
                </td>
              </tr>
              <tr>
                <td class="row-header">権限</td>
                <td class="separate">:</td>
                <td>
                  <div
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: flex-start;
                    "
                  >
                    <v-select
                      class="mb-3 mr-5"
                      :items="state.authCode"
                      item-title="label"
                      item-value="value"
                      v-model="state.parent.auth_code"
                      :disabled="
                        props.userInfo.nickname === state.parent.nickname
                      "
                      variant="underlined"
                      hide-details
                    ></v-select>
                    <v-btn
                      color="keyakiwakaba"
                      density="compact"
                      :disabled="
                        props.userInfo.nickname === state.parent.nickname
                      "
                      @click="changeAuth"
                      >Update</v-btn
                    >
                  </div>
                </td>
              </tr>
              <tr>
                <td class="row-header">パスワード</td>
                <td class="separate">:</td>
                <td v-if="state.parent.reset_flg">
                  <v-text-field
                    density="compact"
                    variant="solo"
                    bg-color="#d8d8d8"
                    v-model="state.parent.initial_password"
                    append-inner-icon="mdi-content-copy"
                    hide-details
                    readonly
                    @click:append-inner="copyToClipboard"
                  >
                  </v-text-field>
                </td>
                <td v-if="!state.parent.reset_flg">
                  <v-btn
                    color="error"
                    prepend-icon="mdi-alert-circle"
                    @click="passwordInitialize"
                  >
                    Password Reset
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </v-table>
        </v-form>
        <v-snackbar
          :timeout="2000"
          color="warning"
          v-model="state.snack.visible"
          rounded="pill"
          min-width="95%"
        >
          <p class="text-center">{{ state.snack.msg }}</p>
        </v-snackbar>
      </v-col>
    </v-card>
  </v-dialog>
</template>

<script>
import { computed, reactive } from "vue";
import axios from "@/plugins/axios.js";
import store from "@/store";
import { BASE_URL } from "@/data";

export default {
  name: "UpdateParentComponent",
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    userInfo: {
      nickname: {
        type: String,
        required: true,
      },
    },
  },
  setup(props, context) {
    const state = reactive({
      parent: {
        id: 0,
        nickname: "",
        sex: "",
        auth_code: 0,
        reset_flg: false,
        initial_password: "",
      },
      child: null,
      authCode: [
        { label: "一般", value: 0 },
        { label: "役員", value: 1 },
        { label: "管理者", value: 2 },
      ],
      snack: {
        visible: false,
        msg: "",
      },
    });

    state.parent = computed(() => {
      return store.getters.getParent;
    });

    state.child = computed(() => {
      return store.getters.getChild;
    });

    /**
     * 保護者編集画面を閉じる
     */
    const closeUpdateDialog = () => {
      context.emit("close_dialog");
    };

    /**
     * 権限変更
     */
    const changeAuth = async () => {
      const params = {
        id: state.parent.id,
        auth_code: state.parent.auth_code,
      };
      try {
        const data = await axios(`${BASE_URL}change_auth`, "patch", params);
        state.snack = {
          visible: true,
          msg: data.msg,
        };
        context.emit("parent_update");
      } catch (error) {
        console.log("ERROR", error);
      }
    };

    /**
     * パスワードの初期化
     */
    const passwordInitialize = async () => {
      try {
        const data = await axios(
          `${BASE_URL}password_reset?id=${state.parent.id}`,
          "patch"
        );
        state.snack = {
          visible: true,
          msg: data.msg,
        };
        context.emit("parent_update");
      } catch (error) {
        console.log("ERROR", error);
      }
    };

    /**
     * クリップボードにコピー
     */
    const copyToClipboard = () => {
      const copyText = state.parent.initial_password;
      navigator.clipboard.writeText(copyText);
      state.snack = {
        visible: true,
        msg: "Copied!!",
      };
    };

    return {
      state,
      props,
      closeUpdateDialog,
      changeAuth,
      passwordInitialize,
      copyToClipboard,
    };
  },
};
</script>

<style scoped lang="scss">
.row-header {
  text-align: right;
  color: #7e7e7e;
}
.separate {
  padding: 0 !important;
}

::v-deep(.v-text-field input) {
  color: #b2b2b2 !important;
}

::v-deep(div.v-field) {
  max-width: 26rem !important;
}

::v-deep(.v-select) {
  min-width: 6rem !important;
  max-width: 6rem !important;
}
</style>
