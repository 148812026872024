const lastNameRules = [
  (v) => !!v || "姓(ひらがな)は必須項目です",
  (v) => v.length <= 20 || "姓は20文字以下にしてね",
  (v) => /^[ぁ-ん]+$/.test(v) || "姓はひらがなで入力してね！!",
];

const firstNameRules = [
  (v) => !!v || "名(ひらがな)は必須項目です",
  (v) => v.length <= 20 || "名は20文字以下にしてね",
  (v) => /^[ぁ-ん]+$/.test(v) || "名はひらがなで入力してね！!",
];

const nicknameRules = [
  (v) => !!v || "ニックネームは必須項目です",
  (v) => v.length <= 20 || "ニックネームは20文字以下にしてね",
];

const passRules = [
  (v) => !!v || "パスワードは必須項目です",
  (v) => v.length <= 32 || "パスワードは16文字以下にしてね",
  (v) => v.length >= 8 || "パスワードは8文字以上にしてね",
  (v) => /^[ -~]+$/.test(v) || "パスワードは半角英数記号のみです",
];

const pageCategoryRules = [
  (v) => !!v || "カテゴリ名は必須項目です",
  (v) => v.length <= 20 || "カテゴリ名は20文字以下にしてね",
];

const kanjiNameRules = (field) => [
  (v) => !v || v.length <= 5 || `${field}(漢字)は5文字以下にしてね`,
];

const englishNameRules = (field) => [
  (v) => !v || v?.length <= 20 || `${field}(ローマ字)は20文字以下にしてね`,
  (v) =>
    !v || /^[a-zA-Z]+$/.test(v) || `${field}(ローマ字)は半角英字で入力してね`,
];

const deadDateRules = [(v) => !!v || "期限は必須項目です"];

const birthdayRules = [(v) => !!v || "生年月日は必須項目です"];

const sexRules = [(v) => !!v || "性別は必須項目です"];

const classRules = [(v) => !!v || "クラスは必須項目です"];

const needQtyRules = [
  (v) => !!v || "枚数は必須項目です",
  (v) => 0 < v || "枚数は0より多くしてね",
  (v) => /^[0-9]+$/.test(v) || "半角数字で入力してください",
];

const titleRules = [
  (v) => !!v || "タイトルは必須項目です",
  (v) => v.length <= 30 || "タイトルは30文字以下にしてね",
];

export {
  lastNameRules,
  passRules,
  pageCategoryRules,
  deadDateRules,
  needQtyRules,
  nicknameRules,
  firstNameRules,
  birthdayRules,
  sexRules,
  classRules,
  titleRules,
  kanjiNameRules,
  englishNameRules,
};
