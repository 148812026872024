import { createStore } from "vuex";

const store = createStore({
  state() {
    return {
      className: "",
      parent: null,
      child: null,
    };
  },

  getters: {
    getClassName(state) {
      return state.className;
    },

    getParent(state) {
      return state.parent;
    },

    getChild(state) {
      return state.child;
    },
  },

  mutations: {
    setClassName(state, className) {
      state.className = className;
    },

    setParent(state, parent) {
      state.parent = parent;
    },

    setChild(state, child) {
      state.child = child;
    },
  },

  actions: {
    setClassName(context, className) {
      context.commit("setClassName", className);
    },

    setParent(context, parent) {
      context.commit("setParent", parent);
    },

    setChild(context, child) {
      context.commit("setChild", child);
    },
  },
});

export default store;
