<template>
  <!-- 削除確認ダイアログ -->
  <DialogComponent
    :dialog="state.deleteConfDialog"
    :dialogInfo="DELETE_CONF_DIALOG_INFO"
    @dialog-event="dialogEvent"
  />
  <!-- 保存確認ダイアログ -->
  <DialogComponent
    :dialog="state.saveConfDialog"
    :dialogInfo="SAVE_CHILD_CONF_DIALOG_INFO"
    @dialog-event="dialogEvent"
  />
  <!-- キャンセル確認ダイアログ -->
  <DialogComponent
    :dialog="state.cancelConfDialog"
    :dialogInfo="CANCEL_CHILD_CONF_DIALOG_INFO"
    @dialog-event="dialogEvent"
  />
  <v-dialog
    v-model="props.dialog"
    fullscreen
    :scrim="false"
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar color="member" class="white toolbar">
        <v-toolbar-title class="white title-text text-center">
          園児詳細
        </v-toolbar-title>
        <v-btn icon @click="closeChildDetailDialog()">
          <v-icon class="white">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-col cols="12" class="pa-5 header-space">
        <v-col cols="12" class="align-self-center text-center">
          <v-avatar
            class="mb-5"
            :image="avatarUrl(state.childInfo.avatar_image)"
            size="250"
          ></v-avatar>
          <TitleComponent
            :title="`${state.childInfo.last_name} ${state.childInfo.first_name}`"
          />
        </v-col>
        <v-col cols="12">
          <!-- 編集モード -->
          <template v-if="state.isEditMode">
            <v-form ref="form">
              <TextFieldComponent
                autofocus
                label="姓(ひらがな)"
                v-model="state.childInfo.last_name"
                type="text"
                refs="last_name"
                max="20"
              />
              <TextFieldComponent
                label="名(ひらがな)"
                v-model="state.childInfo.first_name"
                type="text"
                refs="first_name"
                max="20"
              />
              <v-select
                :items="SELECT_SEX"
                item-title="sex"
                v-model="state.childInfo.sex"
                label="性別"
                variant="underlined"
                color="keyakiwakaba"
                :rules="sexRules"
              ></v-select>
              <TextFieldComponent
                label="生年月日"
                v-model="state.childInfo.birthday"
                type="date"
                refs="birthday"
              />
            </v-form>
            <!-- 退園ボタン -->
            <v-btn
              block
              color="error"
              class="mb-10"
              prepend-icon="mdi-alert-circle"
              append-icon="mdi-alert-circle"
              @click="state.deleteConfDialog = true"
            >
              <p>退園</p>
            </v-btn>
            <v-col class="end">
              <v-btn @click="cancelEdit" color="error" variant="outlined">
                <p>キャンセル</p>
              </v-btn>
              <v-btn color="keyakiwakaba" class="ml-3" @click="updateChild">
                <p>更新</p>
              </v-btn>
            </v-col>
          </template>
          <!-- 閲覧モード -->
          <template v-else>
            <v-row class="mb-3">
              <v-col cols="8" offset="2">
                <p class="sub-color mb-5 content">
                  性別: {{ state.childInfo.sex }}
                </p>
                <p class="sub-color content">
                  誕生日: {{ state.childInfo.birthday_label }}
                </p>
              </v-col>
            </v-row>
            <template v-if="state.addInfo.is_family">
              <v-col class="text-center">
                <p class="mb-3 sub-color">
                  編集したい場合は<span
                    class="main-color font-accent font-weight-bold"
                    @click="router.push({ name: 'mypage' })"
                    >マイページ</span
                  >へ
                </p>
              </v-col>
            </template>
          </template>
          <v-col class="text-center">
            <!-- 編集ボタン - 管理者のみ表示 -->
            <template v-if="state.addInfo.is_admin && !state.isEditMode">
              <v-btn @click="state.isEditMode = true" color="keyakiwakaba">
                <p>編集</p>
              </v-btn>
            </template>
          </v-col>
        </v-col>
      </v-col>
    </v-card>
    <v-snackbar
      :timeout="2000"
      color="warning"
      v-model="state.snack.visible"
      rounded="pill"
      min-width="95%"
    >
      <p class="text-center">{{ state.snack.msg }}</p>
    </v-snackbar>
  </v-dialog>
</template>

<script>
import axios from "@/plugins/axios";
import { reactive, watch, toRefs, ref } from "vue";
import {
  BASE_URL,
  DELETE_CONF_DIALOG_INFO,
  SAVE_CHILD_CONF_DIALOG_INFO,
  CANCEL_CHILD_CONF_DIALOG_INFO,
  SELECT_SEX,
} from "@/data";
import { sexRules } from "@/validationRules";
import moment from "moment";
import router from "@/router";
import TitleComponent from "./TitleComponent.vue";
import { avatarUrl, formatMsg } from "@/utility";
import DialogComponent from "@/components/DialogComponent.vue";
import TextFieldComponent from "@/components/TextFieldComponent.vue";
import lodash from "lodash";

export default {
  name: "ChildDetailComponent",
  components: { TitleComponent, DialogComponent, TextFieldComponent },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    childId: {
      type: Number,
      required: true,
    },
  },
  emits: ["close_dialog"],
  setup(props, context) {
    const form = ref();
    const state = reactive({
      initialChildInfo: {},
      childInfo: {},
      addInfo: {
        is_admin: false,
        is_family: false,
      },
      deleteConfDialog: false,
      saveConfDialog: false,
      cancelConfDialog: false,
      snack: {
        visible: false,
        msg: "",
      },
      isEditMode: false,
    });

    /**
     * 初期情報を取得
     */
    const getChildInfo = async () => {
      // 修正するIDを取得
      if (props.childId !== 0) {
        try {
          const data = await axios(
            `${BASE_URL}child?id=${props.childId}`,
            "get"
          );
          setInitialData(data);
        } catch (error) {
          console.log("ERROR---@RegistImgComponent/category_id/get", error);
        }
      }
    };

    /**
     * 園児情報の設定
     */
    const setInitialData = (childInfo) => {
      state.childInfo = childInfo;
      state.addInfo = childInfo.add_info;
      state.childInfo.birthday_label = moment(childInfo.birthday).format(
        "YYYY年MM月DD日"
      );
      state.initialChildInfo = lodash.cloneDeep(childInfo);
    };

    getChildInfo();

    const { childId } = toRefs(props);
    watch(childId, () => {
      // 初期データ取得
      getChildInfo();
    });

    /**
     * 更新
     */
    const updateChild = async () => {
      const { valid } = await form.value.validate();
      if (valid) {
        try {
          const { childInfo } = state;
          const params = {
            birthday: childInfo.birthday,
            first_name: childInfo.first_name,
            last_name: childInfo.last_name,
            sex: childInfo.sex == "boy" ? 0 : 1,
          };
          const data = await axios(
            `${BASE_URL}child?id=${props.childId}`,
            "patch",
            params
          );
          state.isEditMode = false;
          setInitialData(data);
          state.snack = {
            visible: true,
            msg: "更新しました",
          };
        } catch (error) {
          if (
            error.response &&
            error.response.data &&
            error.response.data.msg
          ) {
            state.errMsg = formatMsg(error.response.data.msg);
          } else {
            console.log("ERROR---@ChildDetailComponent/child/patch", error);
          }
        }
      }
    };

    /**
     * 園児退園処理
     */
    const deleteNurChild = async () => {
      const params = {
        del_flg: true,
      };
      try {
        await axios(`${BASE_URL}child?id=${props.childId}`, "patch", params);
        state.snack = {
          visible: true,
          msg: "退園完了！",
        };
        router.go({ path: router.currentRoute.path, force: true });
      } catch (e) {
        alert(e);
      }
    };

    /**
     * ダイアログのボタンの処理分岐
     * @param {String} kinds ボタン種別
     */
    const dialogEvent = (kinds) => {
      switch (kinds) {
        // ** 削除確認ダイアログ **
        case "quit":
          // 削除しないでもどる
          state.deleteConfDialog = false;
          break;
        case "delete":
          state.deleteConfDialog = false;
          deleteNurChild();
          break;
        case "back":
          state.saveConfDialog = false;
          break;
        case "close":
          revertToChildInfo();
          state.saveConfDialog = false;
          closeChildDetailDialog();
          break;
        case "edit":
          state.cancelConfDialog = false;
          break;
        case "cancel":
          revertToChildInfo();
          state.cancelConfDialog = false;
          state.isEditMode = false;
      }
    };

    /**
     * データを編集前の園児情報に戻す
     */
    const revertToChildInfo = () => {
      state.childInfo = state.initialChildInfo;
    };

    /**
     * キャンセルボタン押下時処理
     */
    const cancelEdit = () => {
      if (isEditedChildInfo()) {
        state.cancelConfDialog = true;
      } else {
        state.isEditMode = false;
      }
    };

    /**
     * 園児情報が変更されたかを確認し返却
     */
    const isEditedChildInfo = () => {
      return !lodash.isEqual(state.childInfo, state.initialChildInfo);
    };

    /**
     * 園児詳細画面を閉じる
     */
    const closeChildDetailDialog = () => {
      if (isEditedChildInfo()) {
        state.saveConfDialog = true;
      } else {
        state.isEditMode = false;
        context.emit("close_dialog", "childDetailDialog");
      }
    };

    return {
      SELECT_SEX,
      DELETE_CONF_DIALOG_INFO,
      SAVE_CHILD_CONF_DIALOG_INFO,
      CANCEL_CHILD_CONF_DIALOG_INFO,
      props,
      state,
      closeChildDetailDialog,
      avatarUrl,
      deleteNurChild,
      dialogEvent,
      updateChild,
      cancelEdit,
      moment,
      router,
      form,
      sexRules,
    };
  },
};
</script>

<style scoped lang="scss">
.content {
  border-bottom: 2px double #fff450;
}
.img-prev {
  width: 100%;
}
</style>
