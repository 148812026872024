<template>
  <DialogComponent
    :dialog="state.deleteConfDialog"
    :dialogInfo="DELETE_CONF_DIALOG_INFO"
    @dialog-event="dialogEvent"
  />
  <v-container>
    <HeaderComponent />
    <v-col cols="12" class="mx-0 px-0">
      <TitleComponent title="マイページ" />
      <v-card variant="outlined" flat class="hogosha">
        <v-toolbar density="compact" color="member" dark="false">
          <v-toolbar-title class="white">保護者情報</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn class="white" icon :disabled="state.mode !== 0">
            <v-icon @click="changeMode(1)">mdi-pencil-circle-outline</v-icon>
          </v-btn>
        </v-toolbar>
        <v-col cols="auto" align="center">
          <v-form ref="parentForm">
            <v-text-field
              class="pa-2"
              color="keyakiwakaba"
              variant="underlined"
              label="ニックネーム"
              v-model="state.nur_parent.nickname"
              type="text"
              max="20"
              :readonly="state.mode === 0"
              :rules="nicknameRules"
            ></v-text-field>
            <p
              class="text-center text-overline sub-color"
              v-if="state.mode === 0"
            >
              パスワード変更は<span
                class="member-color font-accent font-weight-bold"
                @click="router.push({ name: 'password' })"
                >こちら</span
              >
            </p>
            <v-col cols="12" v-if="state.mode === 1">
              <v-btn
                class="mr-1 btn-cancel"
                size="small"
                color="primary"
                @click="resetForm()"
                >キャンセル</v-btn
              >
              <v-btn
                class="ml-1"
                color="keyakiwakaba"
                size="small"
                @click="updateParent"
                >更新</v-btn
              >
            </v-col>
          </v-form>
        </v-col>
      </v-card>
      <br />
      <v-card variant="outlined" flat class="enji">
        <v-toolbar density="compact" color="admin" dark="false">
          <v-toolbar-title>My園児情報</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon :disabled="state.mode !== 0">
            <v-icon @click="state.mode = 2">mdi-pencil-circle-outline</v-icon>
          </v-btn>
        </v-toolbar>
        <v-row class="ma-0" v-if="state.mode !== 2">
          <v-col cols="12" class="align-self-center text-center pb-0">
            <v-avatar
              class="mb-5"
              :image="avatarUrl(state.nur_child.avatar_image)"
              size="200"
            ></v-avatar>
            <TitleComponent
              :title="`${state.nur_child.last_name} ${state.nur_child.first_name}`"
            />
          </v-col>
          <v-row class="mb-3">
            <v-col cols="10" offset="2">
              <p
                class="sub-color mb-2 content"
                v-if="
                  state.nur_child.last_name_knj &&
                  state.nur_child.first_name_knj
                "
              >
                漢字表示: {{ state.nur_child.last_name_knj }}&nbsp;{{
                  state.nur_child.first_name_knj
                }}
              </p>
              <p class="sub-color mb-2 content" v-else>漢字表記: 未登録</p>
              <p
                class="sub-color content"
                v-if="
                  state.nur_child.last_name_eng &&
                  state.nur_child.first_name_eng
                "
              >
                英字表記: {{ state.nur_child.last_name_eng }}&nbsp;{{
                  state.nur_child.first_name_eng
                }}
              </p>
              <p class="sub-color content" v-else>英字表記: 未登録</p>
            </v-col>
          </v-row>
        </v-row>
        <v-row class="ma-0" v-if="state.mode === 2">
          <v-col cols="4" class="pl-4 pr-0 align-self-center">
            <v-badge
              v-if="state.mode === 2 && state.isImgExist"
              overlap
              offset-x="5"
              offset-y="5"
              color="keyakiwakaba"
              icon="mdi-close"
              @click="deleteCategoryDialog"
            >
              <v-avatar
                :image="avatarUrl(state.nur_child.avatar_image)"
                size="100"
              ></v-avatar>
            </v-badge>
            <template v-if="state.mode !== 2 || !state.isImgExist">
              <v-avatar
                :image="avatarUrl(state.nur_child.avatar_image)"
                size="100"
              ></v-avatar>
            </template>
          </v-col>
          <v-col cols="8">
            <v-form ref="childForm">
              <v-text-field
                class="pa-2"
                color="keyakiwakaba"
                variant="underlined"
                label="姓（ひらがな）"
                v-model="state.nur_child.last_name"
                type="text"
                :readonly="state.mode !== 2"
                :rules="lastNameRules"
              ></v-text-field>
              <v-text-field
                class="pa-2"
                color="keyakiwakaba"
                variant="underlined"
                label="名（ひらがな）"
                v-model="state.nur_child.first_name"
                type="text"
                hide-details
                :disabled="state.mode === 2"
                :readonly="state.mode !== 2"
              ></v-text-field>
              <v-text-field
                class="pa-2"
                color="keyakiwakaba"
                variant="underlined"
                label="姓（漢字）"
                v-model="state.nur_child.last_name_knj"
                type="text"
                hide-details
                :readonly="state.mode !== 2"
                :rules="kanjiNameRules('姓')"
              ></v-text-field>
              <v-text-field
                class="pa-2"
                color="keyakiwakaba"
                variant="underlined"
                label="名（漢字）"
                v-model="state.nur_child.first_name_knj"
                type="text"
                hide-details
                :readonly="state.mode !== 2"
                :rules="kanjiNameRules('名')"
              ></v-text-field>
              <v-text-field
                class="pa-2"
                color="keyakiwakaba"
                variant="underlined"
                label="姓（ローマ字）"
                v-model="state.nur_child.last_name_eng"
                type="text"
                hide-details
                :readonly="state.mode !== 2"
                :rules="englishNameRules('姓')"
              ></v-text-field>
              <v-text-field
                class="pa-2"
                color="keyakiwakaba"
                variant="underlined"
                label="名（ローマ字）"
                v-model="state.nur_child.first_name_eng"
                type="text"
                hide-details
                :readonly="state.mode !== 2"
                :rules="englishNameRules('名')"
              ></v-text-field>
              <v-file-input
                class="pa-2"
                v-if="state.mode === 2"
                accept="image/png,image/jpeg"
                variant="underlined"
                label="画像を選択"
                hide-details
                v-on:change="setTempAvatar($event)"
              ></v-file-input>
            </v-form>
          </v-col>
          <v-col
            cols="12"
            class="text-center pa-0 mb-2"
            v-if="state.mode === 2"
          >
            <v-btn
              class="mr-1 btn-cancel"
              color="primary"
              size="small"
              @click="resetForm()"
              >キャンセル</v-btn
            >
            <v-btn
              class="ml-1"
              color="keyakiwakaba"
              size="small"
              @click="updateChild"
              >更新</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
      <v-snackbar
        :timeout="2000"
        color="warning"
        v-model="state.snack.visible"
        rounded="pill"
        min-width="95%"
      >
        <p class="text-center">{{ state.snack.msg }}</p>
      </v-snackbar>
    </v-col>
  </v-container>
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent.vue";
import TitleComponent from "@/components/TitleComponent.vue";
import { logout, avatarUrl } from "@/utility";
import { reactive, ref } from "vue";
import axios from "@/plugins/axios";
import router from "@/router";
import { BASE_URL, DELETE_CONF_DIALOG_INFO } from "@/data";
import DialogComponent from "@/components/DialogComponent.vue";
import {
  englishNameRules,
  kanjiNameRules,
  lastNameRules,
  nicknameRules,
} from "@/validationRules";

export default {
  name: "MyPageView",
  components: { DialogComponent, TitleComponent, HeaderComponent },
  setup() {
    const userInfo = JSON.parse(sessionStorage.getItem("USER_INFO"));
    if (!userInfo) {
      logout();
    }
    const parentForm = ref();
    const childForm = ref();
    const state = reactive({
      deleteConfDialog: false,
      nur_parent: {
        nickname: "",
      },
      nur_child: {
        last_name: "",
        first_name: "",
        last_name_knj: "",
        first_name_knj: "",
        last_name_eng: "",
        first_name_eng: "",
        avatar_image: "",
      },
      tempFile: null,
      mode: 0, // 0:readMode, 1:parentEditMode, 2:childEditMode
      snack: {
        visible: false,
        msg: "",
      },
      isImgExist: false,
    });

    const getInitialData = async () => {
      try {
        const data = await axios(`${BASE_URL}self_info`, "get");
        state.nur_parent.nickname = data.nickname;
        state.nur_child = data.nur_child;
        state.isImgExist = data.nur_child.avatar_image !== "";
      } catch (e) {
        alert(e);
      }
    };
    getInitialData();

    const changeMode = (mode) => {
      state.mode = mode;
    };

    const resetForm = () => {
      getInitialData();
      changeMode(0);
    };

    const setTempAvatar = (e) => {
      if (e.target.files && e.target.files.length === 1) {
        let file = e.target.files[0];
        file.url = URL.createObjectURL(file);
        state.nur_child.avatar_image = file.url;
        state.tempFile = file;
        state.isImgExist = true;
      }
    };

    const updateParent = async () => {
      const { valid } = await parentForm.value.validate();
      if (!valid) {
        return;
      }
      const params = { nickname: state.nur_parent.nickname };
      try {
        const data = await axios(`${BASE_URL}update_parent`, "post", params);
        state.nur_parent.nickname = data.nickname;
        state.mode = 0;
        state.snack = {
          visible: true,
          msg: "保護者情報を更新しました。",
        };
      } catch (e) {
        alert(e);
      }
    };

    const updateChild = async () => {
      const { valid } = await childForm.value.validate();
      if (!valid) {
        return;
      }
      const params = {
        last_name: state.nur_child.last_name,
        last_name_knj: state.nur_child.last_name_knj,
        first_name_knj: state.nur_child.first_name_knj,
        last_name_eng: toPascalCase(state.nur_child.last_name_eng),
        first_name_eng: toPascalCase(state.nur_child.first_name_eng),
        avatar: state.tempFile,
      };
      try {
        const data = await axios(
          `${BASE_URL}update_child`,
          "post",
          params,
          true
        );
        setChildData(data);
        state.mode = 0;
        state.snack = {
          visible: true,
          msg: "園児情報を更新しました。",
        };
      } catch (e) {
        alert(e);
      }
    };

    const setChildData = (data) => {
      state.nur_child.last_name = data.last_name;
      state.nur_child.first_name = data.first_name;
      state.nur_child.last_name_knj = data.last_name_knj;
      state.nur_child.first_name_knj = data.first_name_knj;
      state.nur_child.last_name_eng = data.last_name_eng;
      state.nur_child.first_name_eng = data.first_name_eng;
      state.nur_child.avatar_image = data.avatar_image;
      state.isImgExist = data.avatar_image !== "";
    };

    const deleteAvatar = async () => {
      try {
        const data = await axios(`${BASE_URL}delete_avatar`, "delete");
        setChildData(data);
        state.mode = 0;
        state.snack = {
          visible: true,
          msg: "園児アイコンを削除しました。",
        };
      } catch (e) {
        alert(e);
      }
    };

    const deleteCategoryDialog = () => {
      state.deleteConfDialog = true;
    };

    /**
     * ダイアログのボタンの処理分岐
     * @param {String} kinds ボタン種別
     */
    const dialogEvent = (kinds) => {
      switch (kinds) {
        case "quit":
          // 削除しないでもどる
          state.deleteConfDialog = false;
          break;
        case "delete":
          state.deleteConfDialog = false;
          deleteAvatar();
          break;
        default:
          break;
      }
    };

    /**
     * 姓名（英字）をパスカルケースに変換
     * @param str
     * @returns {string|string}
     */
    const toPascalCase = (str) => {
      if (!str) {
        return str;
      }
      const lower = str.toLowerCase();
      const upper = str.charAt(0).toUpperCase();
      return lower.replace(/(?<=^.{0})./, upper);
    };

    return {
      userInfo,
      parentForm,
      childForm,
      state,
      router,
      DELETE_CONF_DIALOG_INFO,
      getInitialData,
      changeMode,
      resetForm,
      avatarUrl,
      setTempAvatar,
      updateParent,
      updateChild,
      deleteAvatar,
      deleteCategoryDialog,
      dialogEvent,
      lastNameRules,
      nicknameRules,
      englishNameRules,
      kanjiNameRules,
    };
  },
};
</script>

<style scoped lang="scss">
.hogosha {
  border-color: #f8b978 !important;
}
.enji {
  border-color: #fc6c85 !important;
}
::v-deep(.bg-primary.btn-cancel) {
  color: white !important;
}
</style>
