// const BASE_URL = "http://localhost:3000/api/keyaki/";
const BASE_URL = "https://umai.maguro-tabetai.com/api/keyaki/";

const DELETE_CONF_DIALOG_INFO = {
  text: "本当に削除してもよろし？",
  buttons: [
    {
      text: "やっぱり辞める",
      value: "quit",
    },
    {
      text: "削除する",
      value: "delete",
    },
  ],
};

const SAVE_CONF_DIALOG_INFO = {
  text: "画像は保存されてないけど、閉じても平気?",
  buttons: [
    {
      text: "NO~!",
      value: "back",
    },
    {
      text: "OK!!",
      value: "close",
    },
  ],
};

const RESET_CONF_DIALOG_INFO = {
  text: "本当にリセットしてもよろし？",
  buttons: [
    {
      text: "やっぱり辞める",
      value: "quit",
    },
    {
      text: "リセットする",
      value: "reset",
    },
  ],
};

const SAVE_CATEGORY_CONF_DIALOG_INFO = {
  text: "カテゴリは保存されてないけど、閉じても平気?",
  buttons: [
    {
      text: "NO~!",
      value: "back",
    },
    {
      text: "OK!!",
      value: "close",
    },
  ],
};

const SAVE_CHILD_CONF_DIALOG_INFO = {
  text: "園児情報は保存されてないけど、閉じても平気?",
  buttons: [
    {
      text: "NO~!",
      value: "back",
    },
    {
      text: "OK!!",
      value: "close",
    },
  ],
};

const CANCEL_CHILD_CONF_DIALOG_INFO = {
  text: "園児情報は保存されてないけど、編集モード終了して平気?",
  buttons: [
    {
      text: "やっぱり編集する",
      value: "edit",
    },
    {
      text: "編集やめる",
      value: "cancel",
    },
  ],
};

const DOWNLOAD_CONF_DIALOG_INFO = {
  text: "表示中カテゴリの画像を一括DLしますか？",
  buttons: [
    {
      text: "やっぱり辞める",
      value: "quit",
    },
    {
      text: "ダウンロードする",
      value: "download",
    },
  ],
};

const ALBUM_OPEN_CONF_DIALOG_INFO = {
  text: "編集中のアルバムが全体に公開されます。公開しますか？",
  buttons: [
    {
      text: "まだ公開しない",
      value: "cancel",
    },
    {
      text: "公開する",
      value: "toOpen",
    },
  ],
};

const ALBUM_CLOSE_CONF_DIALOG_INFO = {
  text: "編集中のアルバムは現在公開中です。非公開にしますか？",
  buttons: [
    {
      text: "非公開にしない",
      value: "cancel",
    },
    {
      text: "非公開にする",
      value: "toClose",
    },
  ],
};

const ALBUM_REG_EDIT_CONF_DIALOG_INFO = {
  text: "編集中のアルバムは現在公開中です。変更を加えてよろしいですか？",
  buttons: [
    {
      text: "変更しない",
      value: "undo",
    },
    {
      text: "変更する",
      value: "update",
    },
  ],
};

const ALBUM_SORT_EDIT_CONF_DIALOG_INFO = {
  text: "編集中のアルバムは現在公開中です。変更を加えてよろしいですか？",
  buttons: [
    {
      text: "変更しない",
      value: "doNotSort",
    },
    {
      text: "変更する",
      value: "updSort",
    },
  ],
};

const MENU_OPTION = {
  mypage: {
    title: "マイページ",
    subtitle: "お子さん情報の編集ができます。",
    theme: "member",
    icon: "mdi-account-child-outline",
    page: "mypage",
  },
  images: {
    title: "アルバム画像登録",
    subtitle: "アルバム作成に使用する画像を登録できます。",
    theme: "member",
    icon: "mdi-image-edit-outline",
    page: "images",
  },
  album: {
    title: "アルバム閲覧",
    subtitle: "過去の製本済みアルバムを参照できます。",
    theme: "member",
    icon: "mdi-book-open-page-variant-outline",
    page: "album",
  },
  category: {
    title: "カテゴリ登録",
    subtitle: "アルバム構成に必要なカテゴリを登録できます。",
    theme: "officer",
    icon: "mdi-tag-plus-outline",
    page: "category",
  },
  registStatus: {
    title: "登録状況一覧",
    subtitle: "全員の登録状況を確認できます。",
    theme: "officer",
    icon: "mdi-checkbox-marked-outline",
    page: "registStatus",
  },
  parent: {
    title: "保護者一覧",
    subtitle: "権限変更とパスワードリセットができるよ。",
    theme: "admin",
    icon: "mdi-human-male-child",
    page: "parent",
  },
  children: {
    title: "園児一覧",
    subtitle: "在籍園児の一覧を参照できます。",
    theme: "member",
    icon: "mdi-human-male-child",
    page: "children",
  },
  notice: {
    title: "お知らせ一覧",
    subtitle: "お知らせ(テンプレートのDLはこちら)",
    theme: "member",
    icon: "mdi-information-outline",
    page: "notice",
  },
  albumRegist: {
    title: "アルバム設定",
    subtitle: "製本後の画像登録と表示順が設定できます。",
    theme: "officer",
    icon: "mdi-book-open-blank-variant",
    page: "albumRegist",
  },
  prevImage: {
    title: "登録画像確認",
    subtitle: "登録された画像の確認/個別DLができます。",
    theme: "officer",
    icon: "mdi-image-search-outline",
    page: "prevImage",
  },
};

const SELECT_SEX = [{ sex: "boy" }, { sex: "girl" }];

export {
  BASE_URL,
  DELETE_CONF_DIALOG_INFO,
  SAVE_CONF_DIALOG_INFO,
  RESET_CONF_DIALOG_INFO,
  SAVE_CATEGORY_CONF_DIALOG_INFO,
  SAVE_CHILD_CONF_DIALOG_INFO,
  DOWNLOAD_CONF_DIALOG_INFO,
  MENU_OPTION,
  CANCEL_CHILD_CONF_DIALOG_INFO,
  SELECT_SEX,
  ALBUM_OPEN_CONF_DIALOG_INFO,
  ALBUM_CLOSE_CONF_DIALOG_INFO,
  ALBUM_REG_EDIT_CONF_DIALOG_INFO,
  ALBUM_SORT_EDIT_CONF_DIALOG_INFO,
};
