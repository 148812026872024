const logout = () => {
  sessionStorage.clear();
  window.location.href = "/login";
};

/**
 * 受け取ったmsgの\nを<br />タグに置換して返却
 * @param {String} msg
 * @returns
 */
const formatMsg = (msg) => {
  return msg.replace("\n", "<br />");
};

/**
 * 受け取った画像情報から一時的な画像URLを生成し返却
 * @param {String} image
 * @returns image url
 */
const avatarUrl = (image) => {
  let url = image;
  return url
    ? url
    : new URL(`./assets/no-user-image.jpeg`, import.meta.url).href;
};
export { logout, formatMsg, avatarUrl };
