<template>
  <v-text-field
    color="keyakiwakaba"
    :label="props.label"
    :type="props.type"
    variant="underlined"
    clearable
    required
    :ref="props.refs"
    :rules="validationRule"
    :maxlength="props.max"
    :model-value="props.modelValue"
    :min="props.type == 'number' ? 1 : false"
    @input="$emit('update:modelValue', $event.target.value)"
    :counter="props.max ? true : false"
    :error-messages="props.errorMsg ? props.errorMsg : ''"
    :autofocus="props.autofocus ? props.autofocus : false"
  ></v-text-field>
</template>

<script>
import {
  lastNameRules,
  passRules,
  pageCategoryRules,
  deadDateRules,
  needQtyRules,
  nicknameRules,
  firstNameRules,
  birthdayRules,
  titleRules,
} from "@/validationRules";
export default {
  name: "TextFieldComponent",
  props: {
    label: {
      type: String,
      required: true,
    },
    modelValue: {
      type: [String, Number],
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    refs: {
      type: String,
      required: true,
    },
    max: {
      type: String,
      required: false,
    },
    errorMsg: {
      type: String,
      required: false,
    },
    autofocus: {
      type: Boolean,
      required: false,
    },
  },
  emits: ["update:modelValue"],
  setup(props) {
    let validationRule;
    switch (props.refs) {
      case "last_name":
        validationRule = lastNameRules;
        break;
      case "first_name":
        validationRule = firstNameRules;
        break;
      case "birthday":
        validationRule = birthdayRules;
        break;
      case "nickname":
        validationRule = nicknameRules;
        break;
      case "password":
        validationRule = passRules;
        break;
      case "page_category":
        validationRule = pageCategoryRules;
        break;
      case "dead_date":
        validationRule = deadDateRules;
        break;
      case "need_qty":
        validationRule = needQtyRules;
        break;
      case "title":
        validationRule = titleRules;
        break;
      default:
        break;
    }
    return { props, lastNameRules, validationRule };
  },
};
</script>
