<template>
  <div class="text-center">
    <v-dialog v-model="props.dialog" width="auto">
      <v-card>
        <v-card-text v-html="props.dialogInfo.text"></v-card-text>
        <v-card-text
          class="error text-caption"
          v-if="props.dialogInfo.caption"
          v-html="props.dialogInfo.caption"
        ></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <template
            v-for="button in props.dialogInfo.buttons"
            :key="button.value"
          >
            <v-btn
              color="green-darken-1"
              variant="text"
              @click="$emit('dialogEvent', button.value)"
            >
              {{ button.text }}
            </v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "DialogComponent",
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    dialogInfo: {
      type: Object,
      required: false,
    },
  },
  emits: ["dialogEvent"],
  setup(props) {
    return { props };
  },
};
</script>
