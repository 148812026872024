<template>
  <v-container>
    <HeaderComponent />
    <v-col cols="12" class="my-5 main-color text-center">
      こんにちは<br />
      <span class="font-weight-bold font-accent title-text">
        {{ userInfo.nickname }}
      </span>
      さん
    </v-col>
    <NavigateCardComponent
      v-for="page in state.pages"
      :key="page.id"
      :menuOption="MENU_OPTION[page]"
    ></NavigateCardComponent>
  </v-container>
</template>

<script>
// TODO: i18nも入れようかな
// TODO: エラー処理統一・共通化
// TODO: エラーメッセージ表示確認
import HeaderComponent from "@/components/HeaderComponent.vue";
import { logout } from "@/utility";
import { reactive } from "vue";
import axios from "@/plugins/axios.js";
import { BASE_URL, MENU_OPTION } from "@/data";
import NavigateCardComponent from "@/components/NavigateCardComponent.vue";

export default {
  name: "LoginView",
  components: { NavigateCardComponent, HeaderComponent },
  setup() {
    const userInfo = JSON.parse(sessionStorage.getItem("USER_INFO"));
    if (!userInfo) {
      logout();
    }
    const state = reactive({
      pages: [],
    });

    const getInitialData = async () => {
      try {
        state.pages = await axios(`${BASE_URL}pages`, "get");
      } catch (e) {
        alert(e);
      }
    };
    getInitialData();
    return { userInfo, state, getInitialData, MENU_OPTION };
  },
};
</script>

<style scoped lang="scss"></style>
