<template>
  <!-- ヘッダー -->
  <v-app-bar :elevation="0" :color="state.isOfficer ? 'error' : 'keyakiwakaba'">
    <template v-slot:prepend>
      <v-app-bar-nav-icon @click="changeDrawer()"></v-app-bar-nav-icon>
    </template>
    <v-app-bar-title class="title-text font-logo-en ml-0">
      <RouterLink class="white" :to="{ name: 'top' }">
        Keyakiwakaba Album
      </RouterLink>
    </v-app-bar-title>
    <template v-slot:append>
      <v-col class="text-center py-0" cols="12">
        <v-icon class="white" @click="logout()">mdi-exit-run</v-icon><br />
        <span class="text-caption">logout</span>
      </v-col>
    </template>
  </v-app-bar>
  <v-navigation-drawer v-model="state.drawer" fixed temporary>
    <v-list nav dense>
      <template v-for="page of state.pages" :key="page.name">
        <v-list-item @click="linkTo(page.page)">
          <v-list-item-title class="sub-color">
            {{ page.title }}
          </v-list-item-title>
        </v-list-item>
      </template>
      <v-list-item>
        <v-list-item-title @click="logout()" class="sub-color">
          ログアウト
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { useRouter } from "vue-router";
import { reactive } from "vue";
import { logout } from "@/utility";
import axios from "@/plugins/axios";
import { BASE_URL, MENU_OPTION } from "@/data";

export default {
  name: "HeaderComponent",
  setup() {
    const state = reactive({
      drawer: false,
      isOfficer: false,
      pages: [],
    });
    const getInitialData = async () => {
      try {
        const data = await axios(`${BASE_URL}pages`, "get");
        for (const d of data) {
          if (MENU_OPTION[d]) state.pages.push(MENU_OPTION[d]);
        }
      } catch (error) {
        alert(error);
      }
    };
    getInitialData();

    const router = useRouter();
    const linkTo = (name) => {
      router.push({ name });
    };
    const changeDrawer = () => {
      state.drawer = !state.drawer;
    };
    return { state, changeDrawer, linkTo, logout };
  },
};
</script>
