<template>
  <!-- ダイアログ - 共通 -->
  <DialogComponent
    :dialog="state.dialog"
    :dialogInfo="state.dialogInfo"
    @dialog-event="dialogEvent"
  />
  <v-dialog
    v-model="props.dialog"
    fullscreen
    :scrim="false"
    transition="dialog-bottom-transition"
  >
    <SpinnerComponent :isLoading="state.isLoading" />
    <!-- 画像登録画面 -->
    <v-card>
      <v-toolbar color="member" class="white toolbar">
        <v-toolbar-title class="white title-text text-center">
          画像登録
        </v-toolbar-title>
        <v-btn icon @click="closeImgRegistDialog()">
          <v-icon class="white">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-col cols="12" class="pa-5 header-space">
        <p class="text-subtitle-2 sub-color">
          カテゴリ: {{ state.nurCategory.page_category }}
        </p>
        <p
          class="text-subtitle-2 sub-color"
          v-if="!state.nurCategory.is_officer_only"
        >
          必要枚数:{{
            state.isEnough
              ? `足りてます!(${state.nurCategory.need_qty}枚)`
              : `${state.nurCategory.need_qty}(残り${state.count}枚)`
          }}
          <br />
          <span class="error text-caption">
            期日:
            {{ moment(state.nurCategory.dead_date).format("YYYY年MM月DD日") }}
          </span>
        </p>
        <br />
        <!-- フォーム -->
        <div class="end">
          <v-file-input
            accept="image/png,image/jpeg"
            multiple
            class="font-accent"
            variant="underlined"
            label="画像を選択"
            v-model:model-value="state.uploadFiles"
            v-on:change="addImage($event)"
          ></v-file-input>
          <p class="text-caption error start">
            ※ 画像の拡張子はpng / jpg のみ対応しています。<br />
            ※ 個人ページは1480 × 2100 以上の画像サイズを推奨<br />
            上記サイズ以下になると画面上に表示されている画質よりも悪く印刷される場合があります。
          </p>
          <v-btn
            color="keyakiwakaba"
            :disabled="
              lodash.filter(state.images, ['isRegistered', false]).length <= 0
            "
            class="mt-5"
            @click="registImages"
          >
            <p>画像を登録する</p>
          </v-btn>
          <br />
        </div>
        <template v-for="(image, i) in state.images" :key="image.url">
          <v-row
            no-gutters
            :class="
              image.isRegistered ? 'image-area' : 'image-area-unregistered'
            "
          >
            <v-col cols="6" class="align-self-center">
              <v-img :src="image.url" class="img-prev">
                <v-overlay
                  activator="parent"
                  scroll-strategy="block"
                  class="align-center justify-center"
                >
                  <v-img :src="image.url" width="90vw"></v-img>
                </v-overlay>
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey-lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-col>
            <v-col cols="6" class="pa-3">
              <p :class="image.isRegistered ? 'main-color' : 'error'">
                {{ image.isRegistered ? "登録済" : "未登録" }}
              </p>
              <p class="text-caption" v-if="image.name">{{ image.name }}</p>
              <template v-if="image.wh.h != 0 || image.wh.w != 0">
                <p class="text-caption" v-if="image.wh">
                  {{ image.wh.w }} × {{ image.wh.h }}
                </p>
                <p class="text-caption error" v-if="isTooSmall(image.wh)">
                  画像は小さめです
                </p>
              </template>
              <div class="end">
                <v-btn
                  size="small"
                  variant="outlined"
                  color="error"
                  block
                  class="mt-5"
                  @click="openDialog('delete', i)"
                >
                  削除する
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </template>
      </v-col>
    </v-card>
  </v-dialog>
  <v-snackbar
    :timeout="2000"
    :color="state.snack.color"
    v-model="state.snack.visible"
    rounded="pill"
    min-width="95%"
  >
    <p class="text-center">{{ state.snack.msg }}</p>
  </v-snackbar>
</template>
<script>
import lodash from "lodash";
import axios from "@/plugins/axios";
import moment from "moment";
import { reactive, watch, toRefs } from "vue";
import DialogComponent from "./DialogComponent.vue";
import SpinnerComponent from "@/components/SpinnerComponent.vue";
import {
  SAVE_CONF_DIALOG_INFO,
  DELETE_CONF_DIALOG_INFO,
  BASE_URL,
} from "@/data";

export default {
  name: "RegistImgComponent",
  components: { DialogComponent, SpinnerComponent },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    categoryId: {
      type: Number,
      required: true,
    },
  },
  emits: ["close_dialog"],
  setup(props, context) {
    const ALBUM_PICTURE_URL = `${BASE_URL}album_picture`;
    const state = reactive({
      uploadFiles: [],
      images: [],
      deleteImgIndex: null,
      dialog: false,
      dialogInfo: {
        text: "",
        buttons: [],
      },
      nurCategory: {},
      albumId: 0,
      isEnough: false,
      count: 0,
      snack: {
        visible: false,
        msg: "",
      },
      isLoading: false,
    });

    /**
     * カテゴリーごとの画像を取得
     */
    const getCategoryImages = async () => {
      // 修正するIDを取得
      if (props.categoryId !== 0) {
        try {
          const data = await axios(
            `${ALBUM_PICTURE_URL}?category_id=${categoryId.value}`,
            "get"
          );
          setResData(data);
        } catch (error) {
          console.log("ERROR---@RegistImgComponent/category_id/get", error);
        }
      }
    };

    /**
     * 受け取ったメッセージでスナックバーを表示させる
     * @param {String} msg メッセージ
     */
    const getSnack = (msg, color = "warning") => {
      state.snack = {
        visible: true,
        msg,
        color,
      };
    };

    /**
     * 画像の縦横の大きさを取得
     * @param {Object} img
     */
    const getImageSize = (img) => {
      const image = new Image();
      image.src = img.url;
      img.wh = {
        w: image.width,
        h: image.height,
      };
    };

    const setResData = (data) => {
      state.albumId = data.id;
      state.nurCategory = data.nur_category;
      state.images = data.images;
      state.images.map((img) => ((img.isRegistered = true), getImageSize(img)));
      state.count = state.nurCategory.need_qty - state.images.length;
      state.isEnough = state.count <= 0 ? true : false;
    };

    const { categoryId } = toRefs(props);
    watch(categoryId, () => {
      // 初期データ取得
      getCategoryImages();
      // TODO: 確認 - ファイル名とかが出ないので同じ画像の確認ができない
    });

    /**
     * 画像選択時の処理
     * @param {Object} e 選択ファイル
     */
    const addImage = (e) => {
      if (e.target.files && e.target.files.length != 0) {
        for (const file of e.target.files) {
          // 画像一時表示の為のURLを生成
          file.url = URL.createObjectURL(file);
          // 新規追加画像は未登録flgを追加
          file.isRegistered = false;
          if (state.images.some((img) => img.name == [file.name])) {
            getSnack("その画像はすでに登録されています", "error");
            return;
          }
          // 画像サイズ取得
          const img = new Image();
          img.onload = () => {
            file.wh = {
              w: img.width,
              h: img.height,
            };
            state.images.unshift(file);
          };
          img.src = file.url;
        }
      }
      // 選択ファイルを初期化
      state.uploadFiles = [];
    };

    /**
     * 画像登録
     */
    const registImages = async () => {
      state.isLoading = true;
      const { albumId, nurCategory, images } = state;
      const paramImg = lodash.filter(images, ["isRegistered", false]);
      const params = {
        id: albumId,
        nur_category_id: nurCategory.id,
        page_images: paramImg,
      };
      try {
        const data = await axios(ALBUM_PICTURE_URL, "post", params, true);
        setResData(data);
        state.isLoading = false;
        getSnack("登録しました！");
      } catch (error) {
        state.isLoading = false;
        console.log("ERROR---@RegistImgComponent/album_picture/post", error);
      }
    };

    /**
     * 画像削除処理
     * @param {Number} index
     */
    const deleteImage = async () => {
      state.isLoading = true;
      const delImg = lodash.pullAt(state.images, state.deleteImgIndex)[0];
      if (delImg.id) {
        const { albumId } = state;
        try {
          const data = await axios(
            `${ALBUM_PICTURE_URL}?id=${albumId}&image_id=${delImg.id}`,
            "delete"
          );
          setResData(data);
          getSnack("削除しました!");
        } catch (error) {
          console.log(
            "ERROR---@RegistImgComponent/album_picture/delete",
            error
          );
        }
      }
      state.isLoading = false;
      state.deleteImgIndex = null;
    };

    /**
     * 画像登録画面を閉じる
     */
    const closeRegist = () => {
      context.emit("close_dialog");
    };

    /**
     * 画像登録画面の閉じるボタン押下時
     * 未登録の画像があるかを確認しダイアログを表示させる
     */
    const closeImgRegistDialog = () => {
      if (lodash.find(state.images, { isRegistered: false })) {
        openDialog("closeImgRegist");
      } else {
        closeRegist();
      }
    };

    const openDialog = (kinds, index = null) => {
      switch (kinds) {
        case "closeImgRegist":
          state.dialogInfo = SAVE_CONF_DIALOG_INFO;
          break;
        case "delete":
          state.dialogInfo = DELETE_CONF_DIALOG_INFO;
          state.deleteImgIndex = index;
          break;
        default:
          break;
      }
      state.dialog = true;
    };

    /**
     * 受け取った画像がA5サイズよりも小さいかどうかを確認する。
     * 小さい場合はtrue
     * @param {Object} size {h: xxx, w: xxx}
     */
    const isTooSmall = (size) => {
      const base_height = 2100;
      const base_width = 1480;
      const height = Math.max(size.h, size.w);
      const width = Math.min(size.h, size.w);
      return height < base_height || width < base_width;
    };

    /**
     * ダイアログのボタンの処理分岐
     * @param {String} kinds ボタン種別
     */
    const dialogEvent = (kinds) => {
      switch (kinds) {
        // ** 登録確認ダイアログ **
        case "back":
          // 画像登録画面に戻る(保存確認ダイアログのみ閉じる)
          state.dialog = false;
          break;
        case "close":
          // 画像登録画面を閉じる
          // (保存確認ダイアログ・画像登録ダイアログ両方閉じる)
          state.dialog = false;
          closeRegist();
          break;

        //** 削除確認ダイアログ **
        case "quit":
          // 削除しないでもどる
          state.dialog = false;
          state.deleteImgIndex = null;
          break;
        case "delete":
          state.dialog = false;
          deleteImage();
          break;
        default:
          break;
      }
    };

    return {
      moment,
      state,
      props,
      closeImgRegistDialog,
      addImage,
      dialogEvent,
      openDialog,
      registImages,
      isTooSmall,
      lodash,
    };
  },
};
</script>

<style scoped lang="scss">
.img-prev {
  width: 100%;
}
.image-area {
  width: 100%;
  border: #3fb48b 3px double;
  color: #7e7e7e;
  margin: 15px 0;
}
.image-area-unregistered {
  width: 100%;
  background-color: rgb(255 244 80 / 40%);
  border: #f8b978 3px double;
  color: #7e7e7e;
  margin: 15px 0;
}
::v-deep(.v-overlay__scrim) {
  opacity: 0.6;
}
</style>
