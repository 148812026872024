<template>
  <v-container>
    <SpinnerComponent :isLoading="state.isLoading" />
    <HeaderComponent />
    <v-row>
      <v-col cols="12">
        <TitleComponent title="画像一覧" />
      </v-col>
      <v-col cols="12" v-if="state.overdue" class="mt-0 py-0">
        <v-card variant="outlined" color="admin" class="pb-2">
          <v-card-title class="text-center pb-0"
            >提出期日を超過しています
          </v-card-title>
          <template v-for="(category, i) in state.overdueCategories" :key="i">
            <v-card-subtitle>・{{ category }}</v-card-subtitle>
          </template>
        </v-card>
      </v-col>
      <template v-for="category in state.categories" :key="category.id">
        <v-col cols="12" class="my-5">
          <v-row class="mx-0 px-0">
            <p class="sub-color" v-if="category.is_officer_only">
              {{ category.category_name }}
            </p>
            <p class="sub-color" v-else>
              {{ category.category_name }} ({{ category.need_qty }}枚)
            </p>
            <v-btn
              variant="outlined"
              append-icon="mdi-pencil"
              size="small"
              color="member"
              class="ml-3"
              @click="openRegistImg(category.category_id)"
            >
              <p>登録</p>
            </v-btn>
          </v-row>
          <v-row class="mb-3 ml-1">
            <p class="error text-caption" v-if="!category.is_officer_only">
              期日: {{ moment(category.dead_date).format("YYYY年MM月DD日") }}
            </p>
          </v-row>
          <v-row class="mb-3 ml-1 mr-1 overflow-x-auto flex-nowrap">
            <template v-if="category.image_url.length === 0">
              <p class="sub-color mb-5">画像が登録されていません。</p>
            </template>
            <v-col
              v-for="(url, j) in category.image_url"
              :key="j"
              class="d-flex child-flex ma-0 pa-0"
              cols="4"
            >
              <v-img class="ma-1" :src="url" aspect-ratio="1" cover>
                <v-overlay
                  activator="parent"
                  scroll-strategy="block"
                  class="align-center justify-center"
                >
                  <v-img :src="category.org_image_url[j]" width="90vw"></v-img>
                </v-overlay>
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey-lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-col>
          </v-row>
        </v-col>
      </template>
    </v-row>
  </v-container>
  <!-- 画像登録 ダイアログ -->
  <RegistImgComponent
    :dialog="state.imgRegistDialog"
    @close_dialog="closeImgRegistDialog"
    :categoryId="state.editCategoryId"
  />
</template>

<script>
import axios from "@/plugins/axios.js";
import HeaderComponent from "@/components/HeaderComponent.vue";
import RegistImgComponent from "@/components/RegistImgComponent.vue";
import TitleComponent from "@/components/TitleComponent.vue";
import SpinnerComponent from "@/components/SpinnerComponent.vue";
import { reactive, onBeforeMount } from "vue";
import { BASE_URL } from "@/data";
import moment from "moment";

export default {
  name: "ImagesView",
  components: {
    HeaderComponent,
    RegistImgComponent,
    TitleComponent,
    SpinnerComponent,
  },
  setup() {
    const state = reactive({
      imgRegistDialog: false,
      categories: [],
      editCategoryId: 0,
      isLoading: false,
      overdueCategories: [],
      overdue: false,
    });

    // 初期データ取得
    onBeforeMount(() => {
      getImages();
    });

    // 画像取得
    const getImages = async () => {
      state.isLoading = true;
      try {
        state.categories = await axios(`${BASE_URL}album_pictures`, "get");
        hasOverdue();
        state.isLoading = false;
      } catch (error) {
        state.isLoading = false;
        console.log("ERROR---@ImagesView/album_pictures/get", error);
      }
    };

    /**
     * 期限超過カテゴリの存在判定
     */
    const hasOverdue = () => {
      // initialize
      state.overdue = false;
      state.overdueCategories = [];
      state.categories.forEach((c) => {
        if (
          // !役員専用カテゴリ && 提出期限"日"超過 && 必要枚数未達
          !c.is_officer_only &&
          moment().isAfter(moment(c.dead_date), "day") &&
          c.need_qty > c.image_url.length
        ) {
          state.overdueCategories.push(c.category_name);
          state.overdue = true;
        }
      });
    };

    /**
     * カテゴリーの画像編集画面を開く
     * @param {Integer} id 画像編集するカテゴリーID
     */
    const openRegistImg = (id) => {
      state.editCategoryId = id;
      state.imgRegistDialog = true;
    };

    /**
     * 画像登録画面の閉じるボタン押下時の処理
     */
    const closeImgRegistDialog = () => {
      getImages();
      state.imgRegistDialog = false;
      state.editCategoryId = 0;
    };

    return {
      state,
      closeImgRegistDialog,
      openRegistImg,
      moment,
    };
  },
};
</script>

<style scoped lang="scss"></style>
