<template>
  <v-container>
    <HeaderComponent />
    <v-col cols="12" class="mx-0 px-0">
      <TitleComponent title="カテゴリ一覧" />
      <v-table>
        <template v-for="c in state.classes" :key="`class_${c.id}`">
          <tbody>
            <tr>
              <th class="v-table-header" colspan="3">
                {{ c.grade_name }}
                <v-btn
                  v-if="c.is_edit"
                  variant="outlined"
                  append-icon="mdi-pencil"
                  size="x-small"
                  color="#ffffff"
                  class="edit-btn"
                  @click="state.categoryRegistDialog = true"
                >
                  <p>編集</p>
                </v-btn>
                <v-icon
                  @click="c.visible = !c.visible"
                  v-if="c.categories.length != 0 && !c.visible"
                  class="float-end"
                  icon="mdi-plus-box"
                ></v-icon>
                <v-icon
                  @click="c.visible = !c.visible"
                  v-if="c.categories.length != 0 && c.visible"
                  class="float-end"
                  icon="mdi-minus-box"
                ></v-icon>
              </th>
            </tr>
            <template v-if="c.visible">
              <tr
                v-for="category in c.categories"
                :key="`category_${category.id}`"
                bgcolor="#fffef1"
              >
                <td
                  :class="`text-caption ${
                    category.is_officer_only ? 'error' : 'sub-color'
                  }`"
                >
                  {{ category.page_category }}
                </td>
                <td class="text-caption sub-color">
                  {{
                    category.is_officer_only
                      ? "-"
                      : moment(category.dead_date).format("YYYY年MM月DD日")
                  }}
                </td>
                <td class="text-caption sub-color">
                  {{ category.is_officer_only ? "-" : category.need_qty }}
                </td>
              </tr>
            </template>
          </tbody>
        </template>
      </v-table>
      <CategoryRegistComponent
        :dialog="state.categoryRegistDialog"
        @close_dialog="closeRegistDialog"
        @update_categories="getInitialData"
      />
    </v-col>
  </v-container>
</template>

<script>
import { reactive } from "vue";
import { useStore } from "vuex";
import { BASE_URL } from "@/data";
import axios from "@/plugins/axios.js";
import HeaderComponent from "@/components/HeaderComponent.vue";
import TitleComponent from "@/components/TitleComponent.vue";
import CategoryRegistComponent from "@/components/CategoryRegistComponent.vue";
import moment from "moment";

export default {
  name: "CategoriesView",
  components: { HeaderComponent, TitleComponent, CategoryRegistComponent },
  setup() {
    const store = useStore();
    const state = reactive({
      classes: [],
      categoryRegistDialog: false,
    });

    /**
     * 初期データの取得
     */
    const getInitialData = async () => {
      try {
        state.classes = await axios(`${BASE_URL}categories`, "get");
        state.classes.map((c) => (c.visible = c.is_edit ? true : false));
        const className = state.classes.find((c) => c.is_edit).grade_name;
        store.dispatch("setClassName", className);
      } catch (error) {
        console.log("ERROR---@CategoriesView/categories/get", error);
      }
    };

    getInitialData();

    /**
     * カテゴリー登録ダイアログを閉じる
     */
    const closeRegistDialog = () => {
      state.categoryRegistDialog = false;
    };

    return { state, closeRegistDialog, getInitialData, moment };
  },
};
</script>
<style scoped lang="scss">
::v-deep .v-table-header {
  background-color: #f8b978 !important;
  border-bottom: 2px double #ffffff !important;
  height: 40px !important;
  color: #ffffff;
  font-weight: bold !important;
}

// ::v-deep th {
//   border-bottom: 1px solid red !important;
//   font-family: "Kiwi Maru";
//   font-weight: bold !important;
// }
</style>
